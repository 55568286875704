<template>
    <div class="col-lg-12 col-md-6 mt--30 box-grid-layout no-overlay ">
        <img :src="imgSrc" alt="bare_mental"
            style="display: block; margin-left: auto; margin-right: auto;">
        <div class="table">
            <table class="comparison-table">
                <thead>
                    <tr>
                        <th>Project</th>
                        <th>Cloud servers</th>
                        <th>Physical servers</th>
                        <th>Elastic bare metal</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Delivery speed</td>
                        <td>Real-time provisioning, ready to use immediately</td>
                        <td>Order fulfillment from a few hours to several weeks</td>
                        <td>Rapid provisioning</td>
                    </tr>
                    <tr>
                        <td>Routine use (starting/stopping)</td>
                        <td>Through the GIC control panel</td>
                        <td>Offline ticket</td>
                        <td>Through the GIC control panel</td>
                    </tr>
                    <tr>
                        <td>Performance monitoring</td>
                        <td>Virtualization-based cloud server monitoring</td>
                        <td>Generally unavailable</td>
                        <td>Comprehensive host performance metrics monitoring</td>
                    </tr>
                    <tr>
                        <td>Performance</td>
                        <td>Virtualization, with 5% to 10% overhead</td>
                        <td>100% Physical server</td>
                        <td>100% Physical server</td>
                    </tr>
                    <tr>
                        <td>Isolation</td>
                        <td>Shared resources, unstable performance under high load</td>
                        <td>Single physical server delivery, naturally isolated</td>
                        <td>Single physical server delivery, naturally isolated</td>
                    </tr>
                    <tr>
                        <td>Network service</td>
                        <td>Robust cloud networking features (EIP, elastic bandwidth, GPN, etc.)</td>
                        <td>Single IDC network bandwidth</td>
                        <td>Can utilize various cloud networking features (EIP, elastic bandwidth, GPN, etc.)</td>
                    </tr>
                    <tr>
                        <td>Virtualization</td>
                        <td>Does not support secondary virtualization</td>
                        <td>Support</td>
                        <td>Supports providing virtualization platforms, container cloud platforms, and MySQL database cluster isolation solutions</td>
                    </tr>
                    <tr>
                        <td>Customer operations cost</td>
                        <td>Low</td>
                        <td>High</td>
                        <td>Low</td>
                    </tr>
                    <tr>
                        <td>TCO</td>
                        <td>Low</td>
                        <td>High</td>
                        <td>Low</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="descript">
            <ul class="feature-list">
                <li>
                    Elastic on-demand customization:
                    <ul class="sub-list">
                        <li>Intel Xeon 4216/5218r/6240r/8358p</li>
                        <li>HDD、SSD、NVME</li>
                    </ul>
                </li>
                <li>Over 500 per POD, horizontally scalable</li>
                <li>Leaf-Spine Network architecture, 25G-100G Network, Support RDMA;</li>
                <li>Elastic public network bandwidth, adjustable on demand;</li>
                <li>Multi-cloud and hybrid cloud services, including high-bandwidth, ultra-high performance cloud disks, backup services, and more.</li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
           imgSrc:require('@/assets/images/cloud/bare_mental_1.png')
        }
    },
}

</script>
<style scoped>
.comparison-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 0px;
}

.comparison-table th,
.comparison-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
}

.comparison-table th {
    background-color: #8b0000;
    color: white;
}

.comparison-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.feature-list {
    list-style: none;
    background-color: var(--color-blackest) !important;
    border-radius: 8px;
    padding: 10px 20px 10px 20px;
}

.feature-list > li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    line-height: 1.6;
}

.feature-list > li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: 8px;
    height: 8px;
    background-color: #ff0000;
    border-radius: 50%;
}

.sub-list {
    list-style: none;
    padding-left: 20px;
    margin-top: 10px;
}

.sub-list li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 8px;
    color: #666;
}

.sub-list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: 6px;
    height: 6px;
    background-color: #999;
    border-radius: 50%;
}
</style>