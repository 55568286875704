<template>
    <Layout :header-transparency="true" :show-top-header="false">
        <!-- Start Slider Area -->
        <!-- <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-950 bg-overlay bg_image"
                         :style="{'background-image': `url(${require(`@/assets/images/bg/${slider.image}.jpg`)})`}">
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="col-lg-12">
                                    <div class="inner text-center">
                                        <h1 class="title" v-html="slider.title"/>
                                        <p class="description" v-html="slider.description"/>
                                        <div class="button-group mt--30">
                                            <a class="btn-default btn-large round"
                                               href="#">
                                                Get started now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div> -->
        <!-- End Slider Area -->
        <section class="rn-service-area rn-section-gap">
            <!-- overview -->
            <div class="container" style="padding-bottom: 60px;">

                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle class="mb--50" text-align="center" subtitle="Cloud Live Streaming"
                            title="NetO Live Streaming Overview" description="" data-aos="fade-up"
                            data-aos-delay="60" />
                    </div>
                </div>
                <div class="rn-splite-style bg-color-blackest">
                    <div class="split-wrapper">
                        <div class="row no-gutters radius-10 align-items-center">
                            <div class="col-lg-12 col-xl-6 col-12">
                                <div class="thumbnail">
                                    <img style="background-color: white;"
                                        :src="require('@/assets/images/portfolio/live-streaming-map.png')"
                                        alt="live-streaming-map" />
                                </div>
                            </div>
                            <div class="col-lg-12 col-xl-6 col-12">
                                <div class="split-inner">
                                    <h4 class="title">
                                        Global Node Resource Distribution Map
                                    </h4>
                                    <p class="description">
                                    <ul>
                                        <li>
                                            NetO (Cloud Live Streaming) relies on a cloud-edge integrated architecture
                                            and large-scale node resources to build an efficient streaming media
                                            service.

                                        </li>
                                        <li>Through multi-dimensional node monitoring and elastic node scheduling
                                            management, it ensures a perfect balance between high-quality service and
                                            cost-effectiveness.</li>
                                    </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- architecture -->
            <div class="container">

                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle class="mb--50" text-align="center" subtitle="Cloud Live Streaming"
                            title="Product architecture" description="" data-aos="fade-up" data-aos-delay="60" />
                    </div>
                </div>
                <div class="our-counterup-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="thumbnail" data-aos="fade-up">
                                    <img class="radius w-100"
                                        src="@/assets/images/portfolio/live-streaming-architecture.png" alt="Images" />
                                </div>
                                <div class="rwt-counterup-area ptb--60">
                                    <div class="col-lg-12">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- features -->
            <div class="container" style="padding-bottom: 60px;">

                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle class="mb--50" text-align="center" subtitle="Cloud Live Streaming"
                            title="Features" description="" data-aos="fade-up" data-aos-delay="60" />
                    </div>
                </div>
                <Live_Streaming_Overview service-style="service__style--1 bg-color-blackest radius mt--25 rbt-border"
                    icon-size="62" text-align="left" />
            </div>
            <!-- benefits -->
            <div class="container " style="padding-bottom: 60px;">

                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle class="mb--50" text-align="center" subtitle="Cloud Live Streaming"
                            title="Benefits" description="" data-aos="fade-up" data-aos-delay="60" />
                    </div>
                </div>
                <div class="rn-splite-style bg-color-blackest ">
                    <div class="split-wrapper">
                        <div class="row no-gutters radius-10 align-items-center">
                            <div class="col-lg-12 col-xl-6 col-12">
                                <div class="thumbnail">
                                    <img style="background-color: white;"
                                        :src="require('@/assets/images/portfolio/live-streaming-benefits.png')"
                                        alt="live-streaming-map" />
                                </div>
                            </div>
                            <div class="col-lg-12 col-xl-6 col-12">
                                <div class="split-inner">
                                    <h4 class="title">
                                        Globally distributed large-scale node resources
                                    </h4>
                                    <p class="description">
                                    <ul>
                                        <li>
                                            NetO has more than 3000 live streaming node resources distributed in more
                                            than 120 countries, with available bandwidth reserves up to 100 Tbps.
                                        </li>
                                        <li>
                                            95% of the population can access the nearest data center within 50
                                            milliseconds.
                                        </li>
                                    </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Low latency -->
            <div class="container">

                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle class="mb--50" text-align="center" subtitle="Cloud Live Streaming"
                            title="Low latency" description="" data-aos="fade-up" data-aos-delay="60" />
                    </div>
                </div>
                <div class="row row--15 bg-color-blackest  radius mt--25 rbt-border" v-for="(video, index) in videoData" :key="index">
                    <div >
                        <div class="video-popup icon-center" data-aos="fade-up">
                            <div class="thumbnail">
                                <img class="radius-small" :src="video.thumb" alt="Video Thumbnail" />
                            </div>
                            <div class="video-icon">
                                <Button variant-button-class="rounded-player popup-video" :is-link="false"
                                    @btnClick="imageIndex = index">
                                    <span>
                                        <Icon name="play" size="30" />
                                    </span>
                                </Button>
                            </div>
                        </div>
                        <div class="col-lg-12 col-xl-12 col-12" >
                            <div>
                                <p class="description">
                                <ul>
                                    <li v-for="(describe, index) in video.describe" :key="index">
                                        {{ describe }}
                                    </li>
                                </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <CoolLightBox :items="videoData" :index="imageIndex" @close="imageIndex = null">
                </CoolLightBox>
            </div>

        </section>



    </Layout>
</template>

<script>
import Layout from "../components/common/Layout.vue";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import BlogPost from "../components/blog/BlogPost";
import BlogPostMixin from "../mixins/BlogPostMixin";
import VueSlickCarousel from "vue-slick-carousel";
import Portfolio from "../components/elements/portfolio/Portfolio";
import Progressbar from "../components/elements/progressbar/Progressbar";
import Timeline from "../components/elements/timeline/Timeline";
import ServiceFour from "../components/elements/service/ServiceFour";
//IDC_CDN_PRTFILE_PICTURE
import IDC_CDN_picture from "../components/elements/portfolio/IDC_CDN_picture.vue";
import IDC_CDN_picture_oneRow from "../components/elements/portfolio/IDC_CDN_picture_oneRow.vue";
import Live_Streaming_Overview from '../components/elements/service/Live_Streaming_Overview'
import Icon from "../components/icon/Icon.vue"
import Button from "../components/elements/button/Button.vue"

export default {
    name: 'Startup',
    components: {
        Button,
        Icon,
        Live_Streaming_Overview,
        IDC_CDN_picture_oneRow,
        IDC_CDN_picture,
        ServiceFour,
        Timeline, Progressbar, Portfolio, BlogPost, SectionTitle, Separator, Layout, VueSlickCarousel
    },
    mixins: [BlogPostMixin],
    data() {
        return {
            videoData: [
                {
                    id: 1,
                    thumb: require(`@/assets/images/about/NetO.png`),
                    src: require('@/assets/images/portfolio/live-streaming-latency.mp4'),
                    describe: [
                        "supports a variety of transmission protocols, including SRT, RTMP, QUIC, FLV, HLS, and WebRTC, and ensures ultra-high-quality low-latency transmission based on globally distributed node resources, intelligent scheduling systems, and weak network resistance algorithms.",
                        "The latency varies depending on the specific protocol, with FLV being approximately 3 seconds, HLS around 5 seconds, and WebRTC about 800  milliseconds"]
                },

            ],
            imageIndex: null
        }
    }
}
</script>
<style scoped>
p {
    text-align: left;
    margin: 30px 20px 30px 20px;
    word-break: break-word;
}

li {
    list-style-type: disc;
}
</style>