<!--  -->
<template>
    <div class="table">
        <table>
            <thead>
                <tr>
                    <th>Region</th>
                    <th>City</th>
                    <th v-for="city in cities" :key="city">{{ city }}</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(region, regionName) in matrixData">
                    <tr v-for="(row, cityIndex) in region.cities" :key="`${regionName}-${cityIndex}`">
                        <td v-if="cityIndex === 0" :rowspan="region.cities.length" class="region-cell">
                            {{ regionName }}
                        </td>
                        <td>{{ row.city }}</td>
                        <td v-for="(delay, index) in row.delays" :key="`${row.city}-${index}`"
                            :style="{ backgroundColor: delay ? 'white' : '#F8B5BE' }">
                            {{ delay || '' }}
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cities: ['HongKong', 'Tokyo', 'Taipei', 'Singapore', 'Seoul', 'Mumbai', 'Virginia', 'Dallas', 'LosAngeles', 'Amsterdam', 'Frankfurt', 'SãoPaulo'],
            matrixData: {
                'SouthAmerica Region': {
                    cities: [{
                        city: 'SãoPaulo',
                        delays: ['321~325', '262~266', '295~299', '360~364', '294~298', '428~432', '167~171', '131~135', '161~165', '257~261', '249~253', '']
                    }]
                },
                'EuropeRegion': {
                    cities: [
                        {
                            city: 'Frankfurt',
                            delays: ['155~159', '166~170', '176~180', '188~191', '196~200', '245~249', '146~150', '116~118', '143~147', '7~8', '', '249~253']
                        },
                        {
                            city: 'Amsterdam',
                            delays: ['161~165', '173~177', '182~186', '195~198', '203~207', '248~252', '152~156', '121~123', '149~153', '', '7~8', '257~261']
                        }
                    ]
                },
                'NorthAmerica Region': {
                    cities: [
                        {
                            city: 'LosAngeles',
                            delays: ['149~151', '97~101', '128~130', '183~186', '127~129', '238~242', '57~58', '28~30', '', '149~153', '143~147', '161~165']
                        },
                        {
                            city: 'Dallas',
                            delays: ['169~170', '124~130', '156~160', '203~205', '155~160', '258~262', '31~32', '', '28~30', '121~123', '116~118', '131~135']
                        },
                        {
                            city: 'Virginia',
                            delays: ['199~201', '153~157', '185~188', '234~235', '184~187', '286~288', '', '31~32', '57~58', '152~156', '146~150', '167~171']
                        }
                    ]
                },
                'Asia-Pacific Region': {
                    cities: [
                        {
                            city: 'Mumbai',
                            delays: ['91~95', '143~146', '110~114', '54~58', '170~174', '', '286~288', '258~262', '238~242', '248~252', '245~249', '428~432']
                        },
                        {
                            city: 'Seoul',
                            delays: ['83~84', '30~32', '62~64', '117~119', '', '170~174', '184~187', '155~160', '127~129', '203~207', '196~200', '294~298']
                        },
                        {
                            city: 'Singapore',
                            delays: ['35~36', '87~88', '56~58', '', '117~119', '54~58', '234~235', '203~205', '183~186', '195~198', '188~191', '360~364']
                        },
                        {
                            city: 'Taipei',
                            delays: ['22~24', '32~33', '', '56~58', '62~64', '110~114', '185~188', '156~160', '128~130', '182~186', '176~180', '295~299']
                        },
                        {
                            city: 'Tokyo',
                            delays: ['52~53', '', '32~33', '87~88', '30~32', '143~146', '153~157', '124~130', '97~101', '173~177', '166~170', '262~266']
                        },
                        {
                            city: 'HongKong',
                            delays: ['', '52~53', '22~24', '35~36', '83~84', '91~95', '199~201', '169~170', '149~151', '161~165', '155~159', '321~325']
                        }
                    ]
                }
            }
        }
    }
}
</script>

<style lang='scss' scoped>
.table {
    table {
        border-collapse: collapse;
        width: 100%;

        th,
        td {
            border: 1px solid #ccc;
            padding: 8px;
            text-align: center;
        }

        thead th {
            background-color: #4AC4DC;
            color: white;
        }

        .region-cell {
            background-color: #F8B5BE;
        }
    }
}
</style>