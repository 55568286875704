<template>
    <div class="col-lg-12 col-md-6 mt--30 box-grid-layout no-overlay ">
        <img :src="imgSrc" alt="bare_mental"
            style="display: block; margin-left: auto; margin-right: auto;background-color: white;border-radius: 8px;">
        
        <div class="descript">
            <ul class="feature-list">
                
                <li>Supports RDS MySQL Basic Edition, High Availability Edition (5.6, 5.7, 8.0)</li>
                <li>NoSQL Redis Master-Slave Edition, Cluster Edition (2.8, 4.0, 5.0)</li>
                <li>LVS load balancer version (Layer 4)、Haproxy load balancer version (Layer 4-7)</li>
                <li>
                    MongoDB replica sets, and sharded clusters:
                    <ul class="sub-list">
                        <li>Version support,3.2、3.6、4.0</li>
                        <li>Replica set (three nodes), Sharded cluster (three nodes per shard)</li>
                    </ul>
                </li>
                <li>Supports stable and reliable services with sub-second failover and elastic scalability (Scale Up/Out)</li>
                <li>Supports disaster recovery, backup and restore, account permissions, monitoring and alerting, and other features</li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
           imgSrc:require('@/assets/images/cloud/PAAS_1.png')
        }
    },
}

</script>
<style scoped>
.comparison-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 0px;
}

.comparison-table th,
.comparison-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
}

.comparison-table th {
    background-color: #8b0000;
    color: white;
}

.comparison-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.feature-list {
    list-style: none;
    background-color: var(--color-blackest) !important;
    border-radius: 8px;
    padding: 10px 20px 10px 20px;
}

.feature-list > li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    line-height: 1.6;
}

.feature-list > li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: 8px;
    height: 8px;
    background-color: #ff0000;
    border-radius: 50%;
}

.sub-list {
    list-style: none;
    padding-left: 20px;
    margin-top: 10px;
}

.sub-list li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 8px;
    color: #666;
}

.sub-list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: 6px;
    height: 6px;
    background-color: #999;
    border-radius: 50%;
}
</style>