<!--  -->
<template>
    <div class="container">
        <div class="description">
            <p style="margin: 0px 0px 0px 0;">Internet Exchanges: 22</p>
            <p style="margin: 30px 0px 0px 0;">Prefixes Originated (all): 371</p>
            <p style="margin: 0;">Prefixes Originated (v4): 357</p>
            <p style="margin: 0;">Prefixes Originated (v6): 14</p>
            <p style="margin: 30px 0px 0px 0;">BGP Peers Observed (all): 262</p>
            <p style="margin: 0;">BGP Peers Observed (v4): 242</p>
            <p style="margin: 0;">BGP Peers Observed (v6): 157</p>
            <p style="margin: 30px 0px 0px 0;">IPs Originated (v4): 125,696</p>
            <p style="margin: 0;">AS Paths Observed (v4): 6,406</p>
            <p style="margin: 0;">AS Paths Observed (v6): 2,159</p>
            <p style="margin: 30px 0px 0px 0;">Average AS Path Length (all): 3.975</p>
            <p style="margin: 0;">Average AS Path Length (v4): 4.058</p>
            <p style="margin: 0;">Average AS Path Length (v6): 3.749</p>
        </div>
        <div class="cloud_AS63199">
            <div ref="ipv4Chart" class="chart"></div>
            <div ref="ipv6Chart" class="chart"></div>
        </div>

        <div class="table">
            <table>
                <thead>
                    <tr>
                        <th>ASN</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>AS174</td>
                        <td>Cogent Communications</td>
                    </tr>
                    <tr>
                        <td>AS17408</td>
                        <td>AboveNet Communications Taiwan</td>
                    </tr>
                    <tr>
                        <td>AS3356</td>
                        <td>Level 3 Parent, LLC</td>
                    </tr>
                    <tr>
                        <td>AS9498</td>
                        <td>Bharti Airtel Ltd</td>
                    </tr>
                    <tr>
                        <td>AS3491</td>
                        <td>PCCW Global</td>
                    </tr>
                    <tr>
                        <td>AS4788</td>
                        <td>TM Net, Internet Service Provider</td>
                    </tr>
                    <tr>
                        <td>AS9286</td>
                        <td>KINX</td>
                    </tr>
                    <tr>
                        <td>AS4775</td>
                        <td>Globe Telecoms</td>
                    </tr>
                    <tr>
                        <td>AS1299</td>
                        <td>Telia Company AB</td>
                    </tr>
                    <tr>
                        <td>AS41095</td>
                        <td>IPT LTD</td>
                    </tr>
                </tbody>
            </table>
            <!--  -->
            <table>
                <thead>
                    <tr>
                        <th>ASN</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>AS174</td>
                        <td>Cogent Communications</td>
                    </tr>
                    <tr>
                        <td>AS17408</td>
                        <td>AboveNet Communications Taiwan</td>
                    </tr>
                    <tr>
                        <td>AS3356</td>
                        <td>Level 3 Parent, LLC</td>
                    </tr>
                    <tr>
                        <td>AS1299</td>
                        <td>Telia Company AB</td>
                    </tr>
                    <tr>
                        <td>AS6939</td>
                        <td>Hurricane Electric LLC</td>
                    </tr>
                    <tr>
                        <td>AS2914</td>
                        <td>NTT America, Inc.</td>
                    </tr>
                    <tr>
                        <td>AS3491</td>
                        <td>PCCW Global</td>
                    </tr>
                    <tr>
                        <td>AS6453</td>
                        <td>TATA COMMUNICATIONS (AMERICA) INC</td>
                    </tr>
                    <tr>
                        <td>AS3320</td>
                        <td>Deutsche Telekom AG</td>
                    </tr>
                   
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    components: {},
    data() {
        return {
            ipv4Data: [
                { name: 'AS174', value: 9.2 },
                { name: 'Other', value: 27.3 },
                { name: 'AS17408', value: 9.2 },
                { name: 'AS3356', value: 8.1 },
                { name: 'AS41095', value: 1.5 },
                { name: 'AS1299', value: 1.5 },
                { name: 'AS4775', value: 1.8 },
                { name: 'AS9286', value: 1.8 },
                { name: 'AS9498', value: 8.1 },
                { name: 'AS3491', value: 8.1 },
                { name: 'AS4788', value: 7.1 },
            ],
            ipv6Data: [
                { name: 'Other', value: 9.6 },
                { name: 'AS3320', value: 3.2 },
                { name: 'AS6453', value: 3.2 },
                { name: 'AS3491', value: 4.8 },
                { name: 'AS174', value: 17 },
                { name: 'AS2914', value: 5.8 },
                { name: 'AS6939', value: 8.7 },
                { name: 'AS1299', value: 15.1 },
                { name: 'AS17408', value: 17 },
                { name: 'AS3356', value: 15.1 },
            ],
        };
    },
    mounted() {
        this.initCharts();
    },
    methods: {
        initCharts() {
            const ipv4Chart = echarts.init(this.$refs.ipv4Chart);
            const ipv6Chart = echarts.init(this.$refs.ipv6Chart);

            // Sort ipv4Data and ipv6Data by value in descending order
            this.ipv4Data.sort((a, b) => b.value - a.value);
            this.ipv6Data.sort((a, b) => b.value - a.value);

            const ipv4Option = {
                title: {
                    display: true,
                    text: 'AS63199 IPv4 Peers',
                    left: 'center'
                },
                series: [{
                    type: 'pie',
                    radius: '50%',
                    data: this.ipv4Data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                }],
            };

            const ipv6Option = {
                title: {
                    display: true,
                    text: 'AS63199 IPv6 Peers',
                    left: 'center'
                },
                series: [{
                    type: 'pie',
                    radius: '50%',
                    data: this.ipv6Data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                }],
            };

            ipv4Chart.setOption(ipv4Option);
            ipv6Chart.setOption(ipv6Option);
        },
    },
}
</script>

<style lang='scss' scoped>
.container {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
}

.chart {
    width: 100%;
    height: 400px; // Adjust height as needed
}

.description {
    padding: 20px;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;

    p {
        color: black;
        // font-size: large;
    }
}

.cloud_AS63199 {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 40%;
}

.table {
    margin-top: 20px;
    /* Add some spacing above the table */
    width: 40%;
    border-collapse: collapse;
    /* Ensure borders are collapsed */
    border-bottom: 2px solid #ddd;
    /* 添加下划线 */
}

.table th,
.table td {
    border: none;
    /* Add border to table cells */
    padding: 8px;
    /* Add padding for better spacing */
    line-height: 1;
    /* 修改行高 */
}

.table th {
    text-align: center;
    /* 标题居中 */
}

.table th {
    background-color: #f2f2f2;
    /* Light gray background for header */
}

.table tr:nth-child(even) {
    background-color: #f9f9f9;
    /* 灰色和白色相间 */
}
</style>