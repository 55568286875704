<template>
    <div class="rwt-card">
        <ul class="nav nav-tabs " id="node-tabs" role="tablist">
            <li class="nav-item" v-for="(region, index) in regions" :key="index">
                <a class="nav-link " :class="{ active: index === 0 }" :id="region.name.toLowerCase() + '-tab'"
                    data-bs-toggle="tab" :href="'#' + region.name.toLowerCase() + '-tab-pane'" role="tab"
                    :aria-controls="region.name.toLowerCase() + '-tab-pane'" aria-selected="index === 0">{{ region.name
                    }}</a>
            </li>
        </ul>
        <hr />

        <div class="tab-content box-grid-layout ">
            <div class="tab-pane fade" v-for="(region, index) in regions" :key="index"
                :id="region.name.toLowerCase() + '-tab-pane'" :class="{ show: index === 0, active: index === 0 }">
                <ul class="nav nav-tabs" :id="region.name.toLowerCase() + '-sub-tabs'" role="tablist">
                    <li class="nav-item" v-for="(city, cityIndex) in region.cities" :key="cityIndex">
                        <a class="nav-link" :class="{ active: cityIndex === 0 }" :id="city.id + '-tab'"
                            data-bs-toggle="tab" :href="'#' + city.id + '-tab-pane'" role="tab"
                            :aria-controls="city.id + '-tab-pane'" aria-selected="cityIndex === 0">{{ city.title }}</a>
                    </li>
                </ul>
                <hr />

                <div class="tab-content">
                    <div class="tab-pane fade" v-for="(city, cityIndex) in region.cities" :key="cityIndex"
                        :id="city.id + '-tab-pane'" :class="{ show: cityIndex === 0, active: cityIndex === 0 }">
                        <div class="city-content-container">
                            <div class="city-sidebar">
                                <span v-for="(data, index) in city.data" :key="index">
                                    {{ data.title }}:
                                    <ul>
                                        <li v-for="(item, itemIndex) in data.items" :key="itemIndex">{{ item }}</li>
                                    </ul>
                                </span>
                            </div>
                            <div class="city-main-content">
                                <div class="chart-container">
                                    <div class="grid-table"
                                        :style="{ gridTemplateColumns: `repeat(${getColumnsCount(city)}, minmax(150px, 1fr))` }">
                                        <!-- 渲染表头 -->
                                        <div v-for="(header, index) in city.latencyHeaders" :key="'header-' + index"
                                            class="header">
                                            {{ header }}
                                        </div>

                                        <!-- 渲染表格内容 -->
                                        <div v-for="(row, rowIndex) in city.latencyData" :key="'row-' + rowIndex"
                                            style="display: contents;">
                                            <div v-for="(value, key) in row" :key="rowIndex + '-' + key" class="cell">
                                                {{ value }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- echart bar -->
                                    <Bar :xAxisData="city.xAxisData" :yAxisData="city.yAxisData" />
                                </div>
                                <div class="additional-info">
                                    <span>
                                        <p v-for="(info, index) in city.additionalInfo" :key="index">{{ info }}</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Bar from '../../components/echarts/Bar.vue';

export default {
    components: {
        Bar
    },
    data() {
        return {
            regions: [
                {
                    name: 'Asia',
                    cities: [
                        {id: 'tokyo', title: 'Tokyo', content: 'Content related to Tokyo Node.',
                            data: [
                                {
                                    title: 'Data Center',
                                    items: [
                                        'Equinix TY4,T3+,East Asia Network Core, Operator Presence 400+',
                                        'COLT,T3'
                                    ]
                                },
                                {
                                    title: 'Transmission',
                                    items: [
                                        'Dual Path Diverse Route Dark Fiber',
                                        '200G+ OTN Transmission, 50ms Switching'
                                    ]
                                },
                                {
                                    title: 'Network',
                                    items: [
                                        '200G+ Network Capacity',
                                        'Coverage of Japanese Domestic Operators and International Operators'
                                    ]
                                }, 
                            ],
                            additionalInfo: [
                                "Average Latency of Major Operators' Coverage in Japan",
                                "Coverage quality of major operators in Korea: (Korea has Korea Telecom(4766), SK Telecom(18302) and Samsung SDS(6619) (the average latency is above 100 and the data has been excluded)"
                            ],
                            latencyHeaders: [
                                'Node',
                                'Target Region',
                                'Target Coverage Area',
                                'Average Latency'
                            ],
                            latencyData: [
                                {
                                    node: 'Tokyo',
                                    targetRegion: 'East Asia',
                                    targetCoverageArea: 'Japan',
                                    averageLatency: '14.78ms'
                                },
                                {
                                    node: 'Tokyo',
                                    targetRegion: 'East Asia',
                                    targetCoverageArea: 'South Korea',
                                    averageLatency: '41.61ms'
                                }
                            ],
                            xAxisData: ["SoftBank Corp(17676)",
                                        "NTT Com(4713)",
                                        "KDDI(2516)",
                                        "Arteria Networks(17506)",
                                        "日本教育网(2907)",
                                        "So-net(2527)",
                                        "BIGLOBE(2518)",
                                        "Nifty(2510)"],
                            yAxisData: [13.75, 18.75, 16, 11, 10.5,9.95,13.75, 22.5],
                        },
                        {id: 'seoul', title: 'Seoul', content: 'Content related to Seoul Node.',
                            data: [
                                {
                                    title: 'Data Center',
                                    items: [
                                        'KINX, T3, Neutral Data Center in Korea, Network Core',
                                    ]
                                },
                                {
                                    title: 'Network',
                                    items: [
                                        '100G+ Network Capacity',
                                        'Coverage of Korean Domestic Operators and International Operators'
                                    ]
                                }
                            ],
                            additionalInfo: [
                                "Coverage quality of major operators in Korea: (Korea has Korea Telecom(4766), SK Telecom(18302)and Samsung SDS(6619) (the average latency is above 100 and the data has been excluded)",
                            ],
                            latencyHeaders: [
                                'Node',
                                'Target Region',
                                'Target Coverage Area',
                                'Average Latency'
                            ],
                            latencyData: [
                                {
                                    node: 'Seoul',
                                    targetRegion: 'East Asia',
                                    targetCoverageArea: 'South Korea',
                                    averageLatency: '8.39ms'
                                },
                                
                            ],
                            xAxisData: ["SK Broadband(9318)", "LG(3736)", "Korea Telecom(4756)", "LG(17858)", "SK Telecom(18302)", "LG(9316)"," Dreamline(9457)", "Olive(10036)"],
                            yAxisData: [8.1, 6.8, 8.3, 6, 13, 12.1, 5, 7.5],
                        },
                        {id: 'hong-kong', title: 'Hong Kong', content: 'Content related to Hong Kong Node.',
                         data: [
                                {
                                    title: 'Data Center',
                                    items: [
                                        'Equinix HK2,T3+,Second largest network core in Southeast Asia',
                                        'MegaPlus High-power scalable data center'
                                    ]
                                },
                                {
                                    title: 'Network',
                                    items: [
                                        '100G+ Network Capacity',
                                        'Coverage includes Hong Kong local operators and extensive Southeast Asia coverage.'
                                    ]
                                }
                            ],
                            additionalInfo: [
                                "The coverage quality of each region covered by Hong Kong: (Hong Kong has Ali Cloud (45102) and Smartone (17924); Taiwan has Chunghwa Telecom Mobile (17421). The data has been excluded owing to abnormal coverage latency",
                            ],
                            latencyHeaders: [
                                'Node',
                                'Target Region',
                                'Target Coverage Area',
                                'Average Latency'
                            ],
                            latencyData: [
                                {
                                    node: 'Seoul',
                                    targetRegion: 'Southeast Asia Region',
                                    targetCoverageArea: 'Hong Kong',
                                    averageLatency: '7.43ms'
                                },
                                {
                                    node: 'Seoul',
                                    targetRegion: 'Southeast Asia Region',
                                    targetCoverageArea: 'Macao',
                                    averageLatency: '7.66ms'
                                },
                                {
                                    node: 'Seoul',
                                    targetRegion: 'Southeast Asia Region',
                                    targetCoverageArea: 'Philippines',
                                    averageLatency: '31.45ms'
                                },
                                {
                                    node: 'Seoul',
                                    targetRegion: 'Southeast Asia Region',
                                    targetCoverageArea: 'Taiwan',
                                    averageLatency: '16.19ms'
                                },
                                {
                                    node: 'Seoul',
                                    targetRegion: 'Southeast Asia Region',
                                    targetCoverageArea: 'Singapore',
                                    averageLatency: '38.02ms'
                                },
                            ],
                            xAxisData: ["Hong Kong", "Macao", "Philippines", "Taiwan", "Singapore"],
                            yAxisData: [7.43, 7.66, 31.45, 16.19, 38.02]
,
                        },
                        {id: 'singapore', title: 'Singapore', content: 'Content related to Singapore Node.', 
                        data: [
                                {
                                    title: 'Data Center',
                                    items: [
                                        'Equinix SG3,T3+,Southeast Asian internet hub, carrier presence300+',
                                        'Globalswitch woodland,T3',
                                        'Rackscentral,T3'
                                    ]
                                },
                                {
                                    title: 'Transmission',
                                    items: [
                                        'Dual-path diverse route dark fiber',
                                        'Fiber ring network composed of three sites',
                                        '400G+ OTN transmission with 50ms switching'
                                    ]
                                },
                                {
                                    title: 'Network',
                                    items: [
                                        '400G+ network capacity',
                                        'Covering major operators across Southeast Asian countries'
                                    ]
                                },
                                {
                                    title: 'POP',
                                    items: [
                                        'Kuala Lumpur, Malaysia',
                                        'Jakarta, Indonesia',
                                        'RPOP Philippines'
                                    ]
                                }
                            ], 
                            additionalInfo: [
                            ],
                            latencyHeaders: [
                                'Node',
                                'Target Region',
                                'Target Coverage Area',
                                'Average Latency'
                            ],
                            latencyData: [
                                {
                                    node: 'Singapore',
                                    targetRegion: 'Southeast Asia',
                                    targetCoverageArea: 'Singapore',
                                    averageLatency: '4.15ms'
                                },
                                {
                                    node: 'Singapore',
                                    targetRegion: 'Southeast Asia',
                                    targetCoverageArea: 'Philippines',
                                    averageLatency: '51.46ms'
                                },
                                {
                                    node: 'Singapore',
                                    targetRegion: 'Southeast Asia',
                                    targetCoverageArea: 'Cambodia',
                                    averageLatency: '34.33ms'
                                },
                                {
                                    node: 'Singapore',
                                    targetRegion: 'Southeast Asia',
                                    targetCoverageArea: 'Laos',
                                    averageLatency: '40.04ms'
                                },
                                {
                                    node: 'Singapore',
                                    targetRegion: 'Southeast Asia',
                                    targetCoverageArea: 'Malaysia',
                                    averageLatency: '16.24ms'
                                },
                                {
                                    node: 'Singapore',
                                    targetRegion: 'Southeast Asia',
                                    targetCoverageArea: 'Myanmar',
                                    averageLatency: '51.23ms'
                                },
                                {
                                    node: 'Singapore',
                                    targetRegion: 'Southeast Asia',
                                    targetCoverageArea: 'Thailand',
                                    averageLatency: '33.54ms'
                                },
                                {
                                    node: 'Singapore',
                                    targetRegion: 'Southeast Asia',
                                    targetCoverageArea: 'India',
                                    averageLatency: '74.09ms'
                                },
                                {
                                    node: 'Singapore',
                                    targetRegion: 'Southeast Asia',
                                    targetCoverageArea: 'Indonesia',
                                    averageLatency: '22.78ms'
                                },
                                {
                                    node: 'Singapore',
                                    targetRegion: 'Southeast Asia',
                                    targetCoverageArea: 'Vietnam',
                                    averageLatency: '38.77ms'
                                },
                                
                            ],
                            xAxisData: ["Singapore", "Philippines", "Cambodia", "Laos", "Malaysia", "Myanmar", "Thailand", "India", "Indonesia", "Vietnam"],
                            yAxisData: [4.15, 51.46, 34.33, 40.04, 16.24,51.23,33.54,74.09,22.78,38.77]
                        },
                        {id: 'taipei', title: 'Taipei', content: 'Content related to Taipei Node.', 
                        data: [
                                {
                                    title: 'Data Center',
                                    items: [
                                        'Chief LY, Chief HD, T3 Tier Taipei Network Core',
                                    ]
                                },
                                {
                                    title: 'Network',
                                    items: [
                                        '100G+ Network Capacity',
                                        'Coverage of Japanese Domestic Operators and International Operators'
                                    ]
                                }
                            ], 
                            additionalInfo: [
                                "Network coverage quality of major operators in Taiwan: (Chunghwa Telecom Mobile (17421). The data is abnormal and has been excluded)",
                            ],
                            latencyHeaders: [
                                'Node',
                                'Target Region',
                                'Target Coverage Area',
                                'Average Latency'
                            ],
                            latencyData: [
                                {
                                    node: 'Taipei',
                                    targetRegion: 'Southeast Asia Region',
                                    targetCoverageArea: 'Taiwan',
                                    averageLatency: '10.86ms'
                                },
                            ],
                            xAxisData: ["台湾固网(9924)", "中华电信(3462)", "台湾教育网(1659)", "远传电信(9674)", "SeedNet(4780)", "台湾大哥大(24158)", "亚太电信(7482)", "新世纪资通(9919)", "中嘉和硕(9416)"],
                            yAxisData: [20.5, 12.5, 6.5, 8.5, 12, 6.5,14,10,13]
                        },
                        {id: 'mumbai', title: 'Mumbai', content: 'Content related to Mumbai Node.',
                         data: [
                                {
                                    title: 'Data Center',
                                    items: [
                                        'CtrlS,T4 Mumbai\'s only T4 certified data center',
                                    ]
                                },
                                {
                                    title: 'Network',
                                    items: [
                                        '200G+',
                                        'JIO',
                                        'Airtel',
                                        'Vodafone',
                                        'Tata',
                                        'Extreme IX'
                                    ]
                                },
                                {
                                    title: 'International dedicated line',
                                    items: [
                                        'Two diverse route submarine cables to Singapore',
                                        'Latency68ms-70ms'
                                    ]
                                },
                                
                            ], 
                            additionalInfo: [
                                "Coverage quality of major states in India: (Vodafone India, Uttar Pradesh (38266). The data has been excluded owing to abnormal coverage latency). The public network quality test data of India is displayed by state. The top 20 states with the highest population are selected for data display, and the rest data are not selected.)",
                            ],
                            latencyHeaders: [
                                'Node',
                                'Target Region',
                                'Target Coverage Area',
                                'Average Latency'
                            ],
                            latencyData: [
                                {
                                    node: 'Mumbai',
                                    targetRegion: 'South Asia',
                                    targetCoverageArea: 'India',
                                    averageLatency: '34.62ms'
                                },
                            ],
                            xAxisData: ["Assam", "Andhra Pradesh", "Uttarakhand", "Utcar Pradesh", "Bihar", "Delhi Capital Territory", "The state of Gujarat", "Kra", "Karnataka", "Rapasthan", "Maharashtra", "Punjab", "Tamil Nadu", "West Bengal", "Himachal Pradesh", "Madhya Pradesh"],
                            yAxisData: [48,59.5,37,32,21,32,17,58,29.5,25,10,31,47.5,30,40,37]
                        },
                    ]
                },
                {
                    name: 'North-America',
                    cities: [
                        {id: 'dallas', title: 'Dallas', content: 'Content related to Dallas Node.', 
                        data: [
                                {
                                    title: 'Data Center',
                                    items: [
                                        'Equinix DA1',
                                        'Databank DFW1',
                                        'Databank DFW2',
                                        'Self-built CDS DFW1',
                                    ]
                                },
                                {
                                    title: 'Transmission',
                                    items: [
                                        'Dual-path diverse route dark fiber',
                                        '800G+ OTN Transmission,50msvSwitching'
                                    ]
                                },
                                {
                                    title: 'Network',
                                    items: [
                                        '1T level bandwidth reserve, covering the entire United States',
                                        'Coverage includes all international and U.S. carriers',
                                        'Optimized for Mexico, Central America,and northern South America'
                                    ]
                                }
                            ], 
                            additionalInfo: [
                                "Quality of each region covered by the Dallas node: (no data for Belize)",
                            ],
                            latencyHeaders: [
                                'Node',
                                'Target Region',
                                'Target Coverage Area',
                                'Average Latency'
                            ],
                            latencyData: [
                                {
                                    node: 'Dallas',
                                    targetRegion: 'North America',
                                    targetCoverageArea: 'United States',
                                    averageLatency: '37.29ms'
                                },
                                {
                                    node: 'Dallas',
                                    targetRegion: 'North America',
                                    targetCoverageArea: 'Panama',
                                    averageLatency: '81.90ms'
                                },
                                {
                                    node: 'Dallas',
                                    targetRegion: 'North America',
                                    targetCoverageArea: 'Costa Rica',
                                    averageLatency: '92.57ms'
                                },
                                {
                                    node: 'Dallas',
                                    targetRegion: 'North America',
                                    targetCoverageArea: 'Honduras',
                                    averageLatency: '65.81ms'
                                },
                                {
                                    node: 'Dallas',
                                    targetRegion: 'North America',
                                    targetCoverageArea: 'Mexico',
                                    averageLatency: '40.48ms'
                                },
                                {
                                    node: 'Dallas',
                                    targetRegion: 'North America',
                                    targetCoverageArea: 'Nicaragua',
                                    averageLatency: '70.95ms'
                                },
                                {
                                    node: 'Dallas',
                                    targetRegion: 'North America',
                                    targetCoverageArea: 'El Salvador',
                                    averageLatency: '74.13ms'
                                },
                                {
                                    node: 'Dallas',
                                    targetRegion: 'North America',
                                    targetCoverageArea: 'Guatemala',
                                    averageLatency: '67.28ms'
                                },
                                
                            ],
                            xAxisData: ["United States", "Panama", "Costa Rica", "Honduras", "Mexico", "Nicaragua", "El Salvador", "Guatemala"],
                            yAxisData: [37.29,81.90,92.57,65.81,40.48,70.95,74.13,67.28]
                        },
                        {id: 'los-angeles', title: 'Los Angeles', content: 'Content related to Los Angeles Node.',
                         data: [
                                {
                                    title: 'Data Center',
                                    items: [
                                        'DRT,T3'
                                    ]
                                },
                                {
                                    title: 'Network',
                                    items: [
                                        '100G bandwidth reserve, covering the entire United States',
                                        'Submarine cable landing areas for routes returning to home country'
                                    ]
                                }
                            ], 
                            additionalInfo: [
                            ],
                            latencyHeaders: [
                                'Node',
                                'Target Region',
                                'Target Coverage Area',
                                'Average Latency'
                            ],
                            latencyData: [
                                {
                                    node: 'Los Angeles',
                                    targetRegion: 'North America',
                                    targetCoverageArea: 'United States',
                                    averageLatency: '50.56ms'
                                },
                               
                                
                            ],
                            xAxisData: ["Eastern United States", "Western United States", "Middle America"],
                            yAxisData: [76.5,39.95,57.85]
                        },
                        {id: 'virginia', title: 'Virginia', content: 'Content related to Virginia Node.', 
                        data: [
                                {
                                    title: 'Data Center',
                                    items: [
                                        'DRT,T3+'
                                    ]
                                },
                                {
                                    title: 'Network',
                                    items: [
                                        '200G bandwidth reserve, covering the entire United States',
                                        'Cloud provider concentration area'
                                    ]
                                }
                            ], 
                            additionalInfo: [
                            ],
                            latencyHeaders: [
                                'Node',
                                'Target Region',
                                'Target Coverage Area',
                                'Average Latency'
                            ],
                            latencyData: [
                                {
                                    node: 'Virginia',
                                    targetRegion: 'North America',
                                    targetCoverageArea: 'United States',
                                    averageLatency: '43.90ms'
                                },
                               
                                
                            ],
                            xAxisData: ["Eastern United States", "Western United States", "Middle America"],
                            yAxisData: [25.5,78.78,41.05]
                        },
                    ]
                },
                {
                    name: 'European',
                    cities: [
                        {id: 'frankfurt', title: 'Frankfurt', content: 'Content related to Frankfurt Node.', 
                        data: [
                                {
                                    title: 'Data Center',
                                    items: [
                                        'Equinix FR7,T3+,Europe\'s most critical network,600+Carrier occupancy',
                                        'GlobalSwitch,T3'
                                    ]
                                },
                                {
                                    title: 'Transmission',
                                    items: [
                                        'Dual-path diverse route dark fiber,Fiber length shorter than15KM',
                                        '400G+ OTN Transmission,50msSwitching'
                                    ]
                                },
                                {
                                    title: 'Network',
                                    items: [
                                        'Bandwidth capacity400G+',
                                        'Coverage spans Europe\'s five major telecoms',
                                        'Optimized connectivity to Russia, Turkey, the Middle East, and North Africa',
                                        'Dedicated connection to Marseille and Amsterdam nodes'
                                    ]
                                }
                            ], 
                            additionalInfo: [
                                "Coverage quality of major European countries: (Germany Daimler (31399).  The data is abnormal and has been excluded)",
                            ],
                            latencyHeaders: [
                                'Node',
                                'Target Region',
                                'Target Coverage Area',
                                'Average Latency'
                            ],
                            latencyData: [
                                {
                                    node: 'Frankfurt',
                                    targetRegion: 'Europe',
                                    targetCoverageArea: 'Germany',
                                    averageLatency: '16.82ms'
                                },
                                {
                                    node: 'Frankfurt',
                                    targetRegion: 'Europe',
                                    targetCoverageArea: 'United Kingdom',
                                    averageLatency: '26.36ms'
                                },
                                {
                                    node: 'Frankfurt',
                                    targetRegion: 'Europe',
                                    targetCoverageArea: 'France',
                                    averageLatency: '25.07ms'
                                },
                                {
                                    node: 'Frankfurt',
                                    targetRegion: 'Europe',
                                    targetCoverageArea: 'Italy',
                                    averageLatency: '32.28ms'
                                },
                                {
                                    node: 'Frankfurt',
                                    targetRegion: 'Europe',
                                    targetCoverageArea: 'Netherlands',
                                    averageLatency: '20.64ms'
                                },
                               
                                
                            ],
                            xAxisData: ["Germany", "United Kingdom", "France", "Italy", "Netherlands"],
                            yAxisData: [16.82,26.36,25.07,32.28,20.64]
                        },
                    ]
                }
            ],
            chart: null,
        };
    },
    methods: {
        getColumnsCount(cityData) {
            if (!cityData?.latencyData?.length) return 0;
            return Math.max(...cityData.latencyData.map(item => Object.keys(item).length));
        }
    }
}

</script>
<style scoped>
.rwt-card {
    border-radius: 20px !important;
    padding: 20px;
    background-color: #f9f9f9;
}

.nav-tabs {
    width: 100%;
    border-bottom: none;

    .nav-item {
        flex: 1;
    }

    .nav-link {
        text-align: center;
        padding: 10px 20px;
        border: 1px solid transparent;
        border-radius: 8px;
        color: #fff;
        transition: background-color 0.3s, color 0.3s;
        margin: 0 10px;

        &.active {
            background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95% / 200% 100%;
            color: #fff;
        }

        &:hover {
            background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95% / 200% 100%;
        }
    }

    &+.nav-tabs {
        margin-top: 10px;
    }
}

/* 嵌套选项卡 */
.nav-tabs {

    &#asia-sub-tabs,
    &#north-america-sub-tabs,
    &#european-sub-tabs {
        width: 100%;
    }
}

.grid-table {
    display: grid;
    gap: 1px;
    width: 100%;
    border: 1px solid #4aa4c9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10px;

    .header {
        background-color: #4aa4c9;
        color: white;
        font-weight: bold;
        text-align: center;
        padding: 10px;
    }

    .cell {
        background-color: #ffffff;
        color: black;
        text-align: center;
        padding: 10px;
    }
}

.city-content-container {
    display: flex;
    align-items: stretch;

    .city-sidebar {
        display: flex;
        flex-direction: column;
        max-width: 25%;
        padding: 0px 10px;
        height: 100%;
    }

    .city-main-content {
        width: 75%;
        display: flex;
        flex-direction: column;
        margin-left: 30px;
        height: 100%;

        .chart-container {
            width: 100%;
        }

        .additional-info {
            padding: 10px 20px;
        }
    }
}

@media (max-width: 768px) {
    .grid-table {
        grid-template-columns: 1fr;

        .header,
        .cell {
            padding: 5px;
        }
    }
}
</style>
