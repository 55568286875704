<template>
    <div class="row row--15 service-wrapper">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12" v-for="(service, index) in serviceList" :key=index>
            <div class="service-wrapper" :class="`service ${serviceStyle} ${checkTextAlign}`" data-aos="fade-up"
                data-aos-delay="70">
                <div class="icon">
                    <img :src="service.image" alt="live-streaming Images" />
                </div>
                <div class="content">
                    <h4 class="title w-600">
                        {{ service.title }}
                    </h4>

                    <div class="description b1 color-gray mb--0">
                        <ul>
                            <li v-for="(item, index) in service.description" :key="index"
                                style="list-style-type: disc;">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '../../icon/Icon'

export default {
    name: 'ServiceOne',
    components: { Icon },
    props: {
        serviceStyle: {
            type: String
        },
        iconSize: {
            type: String | Number,
            default: 62
        },
        textAlign: {
            type: String,
            default: 'left'
        }
    },
    data() {
        return {
            serviceList: [
                {
                    image: require('@/assets/images/service/live-streaming-1.png'),
                    title: 'Streaming Distribution',
                    description: [
                        'Supports various push and pull stream protocols such as FLV, HLS, RTC, SRT, WEBRTC and QUIC.',
                        'Supports adaptive bitrate streaming, audio processing, instant streaming and beauty filters.',
                        'Playback is supported on all platforms.',
                    ]
                },
                {
                    image: require('@/assets/images/service/live-streaming-2.png'),
                    title: 'Live Transcoding',
                    description: [
                        'Supports all mainstream audio and video container formats, and codec formats.',
                        'The transcoding cluster fully integrates CPUs, GPUs, ASIC cards, etc., to achieve more efficient real-time transcoding.',
                    ]
                },
                {
                    image: require('@/assets/images/service/live-streaming-3.png'),
                    title: 'Data Statistics',
                    description: [
                        'Provides business data such as live space and stream management, offering real-time service operation analysis to safeguard refined services.',
                    ]
                },
                {
                    image: require('@/assets/images/service/live-streaming-4.png'),
                    title: 'Security Protection',
                    description: [
                        'Support a variety of encryption technologies.',
                        'Provide comprehensive detection and protection to reduce the risks of piracy and hotlink.',
                    ]
                },
            ]
        }
    },
    computed: {
        checkTextAlign() {
            if (this.textAlign === 'left') return 'text-start';
            else if (this.textAlign === 'center') return 'text-center';
            else if (this.textAlign === 'end') return 'text-end';
            else return '';
        }
    }
}
</script>
<style scoped>
.service-wrapper {
    display: flex;
    /* height: 100%; */

    align-items: flex-start;
}

.service-wrapper .icon {
    margin-right: 20px;
    width: 60px; /* 与图片宽度一致 */
    height: 60px; /* 与图片高度一致 */
}


.service-wrapper .content {
    height: 100%;
    flex: 1; /* 确保内容部分占据剩余空间 */
}

.service-wrapper .content .description {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>