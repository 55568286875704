<template>
    <div class="col-lg-12 col-md-6 mt--30 box-grid-layout no-overlay FC">
        <img :src="imgSrc" alt="bare_mental" style="display: block; margin-left: auto; margin-right: auto;">

        <div class="descript">
            <div v-for="(item, index) in features" :key="index" class="feature-card">
                <div class="feature-title">{{ item.title }}</div>
                <div class="feature-content">{{ item.content }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            imgSrc: require('@/assets/images/cloud/resource_pool_1.png'),
            features: [
                {
                    title: 'Dedicated resources',
                    content: 'Users have exclusive access to physical server resources, including dedicated CPUs, memory, NICs, and other physical resources, ensuring that performance requirements are met while avoiding resource contention with other tenants.'
                },
                {
                    title: 'Flexible provisioning',
                    content: 'Flexible provisioning methods, supporting both online and offline application for activation, allowing resources to be created and deleted at any time to save on usage costs'
                },
                {
                    title: 'Customized support',
                    content: 'Supports customizing physical machine configurations according to users\' actual needs, meeting individual user requirements.'
                }
            ]
        }
    },
}

</script>
<style scoped>
.FC {
    background-color: white;

}

.feature-card {
    border: 1px dashed #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    width: 80%;
    margin: 30px auto;
}

.feature-title {
    background-color: #48a7b9;
    color: white;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 15px;
    font-size: 18px;
}

.feature-content {
    line-height: 1.6;
    padding: 0 10px;
    color: black;
}
</style>