<template>
    <Layout :header-transparency="true" :show-top-header="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-950 bg-overlay bg_image"
                        :style="{ 'background-image': `url(${require(`@/assets/images/bg/${slider.image}.jpg`)})` }">
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="col-lg-12">
                                    <div class="inner text-center">
                                        <h1 class="title" v-html="slider.title" />
                                        <p class="description" v-html="slider.description" />
                                        <!-- <div class="button-group mt--30">
                                            <a class="btn-default btn-large round"
                                               href="#">
                                                Get started now
                                            </a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <!-- End Slider Area -->

        <Separator />
        <div class="rwt-portfolio-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="IDC & CDN" title="Presence and Growth"
                            data-aos="fade-up" />
                    </div>
                </div>
                <IDC_CDN_Portfolio_1 :portfolio-data="portfolioData" />
                <IDC_CDN_picture_oneRow :portfolio-data="portfolioData_2"
                    column="col-lg-12 col-md-6 mt--30 box-grid-layout no-overlay" />


                <!-- <IDC_CDN_AdvancedTab :tab-data="tabData" :tab-style="2" style="margin-top: 60px;" /> -->

                <!-- Cloud interconnection delay matrix -->
                <SectionTitle text-align="center"  title="Cloud interconnection delay matrix" data-aos="fade-up" />
                <Cloud_interconnection_delay_matrix />

                <!-- Overseas autonomous region information AS63199 -->
                <SectionTitle text-align="center"  title="Overseas autonomous region information AS63199" data-aos="fade-up" />
                <Cloud_AS63199 />

                <!-- Introduction to the Public Network -->
                <SectionTitle text-align="center"  title="Introduction to the Public Network" data-aos="fade-up" />
                <Bar_h :xAxisData="bar_h.xAxisData" :yAxisData="bar_h.yAxisData" />
            </div>
        </div>

        <!-- node -->
        <Separator />
        <div class="rwt-portfolio-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="IDC & CDN" title="Node Latency"
                            description="Network Coverage in ..." data-aos="fade-up" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-6 mt--30 box-grid-layout no-overlay">
                    <IDC_CDN_Node />
                </div>
            </div>
        </div>

        <Separator />
        <div class="rwt-portfolio-area rn-section-gap">
            <div style="text-align: center;">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="IDC & CDN" title="Network coverage To B"
                            description="" data-aos="fade-up" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-6 mt--30 box-grid-layout no-overlay">
                    <NCTB style="display: inline-block; width: 95%;" />
                </div>
            </div>
        </div>

        <Separator />
        <div class="rwt-portfolio-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="IDC & CDN" title="Cloud node architecture"
                            description="" data-aos="fade-up" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-6 mt--30 box-grid-layout no-overlay">
                    <img src="@/assets/images/cloud/cloud-node-architecture.jpg" alt="Network Topology Diagram">

                </div>
                <Separator style="margin: 60px 0px;" />

                <cloud_platform service-style="service__style--1 bg-color-blackest radius mt--25 rbt-border"
                    icon-size="62" text-align="left" />

                <Separator style="margin: 60px 0px;" />

                <!-- iaas -->
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="IDC & CDN" title="IAAS Product introduction"
                            description="Hardware composition: x86 servers, centralized and distributed storage based on SSD, 25G high-speed fiber optic network"
                            data-aos="fade-up" />
                    </div>
                </div>
                <Cloud_IAAS service-style="service__style--1 bg-color-blackest radius mt--25 rbt-border" icon-size="62"
                    text-align="left" />

                <Cloud_brand style="margin-top: 40px; background-color: white;border-radius: 8px;"
                    :brand-list="brandList4" :brand-style="2" />

                <!-- Galaxy Elastic Bare Metal Platform -->
                <Separator style="margin: 60px 0px;" />

                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="IDC & CDN"
                            title="Galaxy Elastic Bare Metal Platform" description="" data-aos="fade-up" />
                    </div>

                    <Bare_Metal_Platform />
                </div>

                <!-- PAAS -->
                <Separator style="margin: 60px 0px;" />

                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="IDC & CDN"
                            title="Galaxy Elastic Bare Metal Platform" description="" data-aos="fade-up" />
                    </div>
                    <Cloud_PAAS />
                </div>


                <!-- resource pool -->
                <Separator style="margin: 60px 0px;" />

                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="IDC & CDN" title="Dedicated resource pool"
                            description="Different colored cloud servers represent different tenants"
                            data-aos="fade-up" />
                    </div>
                    <cloud_resource_pool />
                </div>
            </div>
        </div>


    </Layout>
</template>

<script>
import Layout from "../../components/common/Layout.vue";
import Separator from "../../components/elements/separator/Separator";
import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
import BlogPost from "../../components/blog/BlogPost";
import BlogPostMixin from "../../mixins/BlogPostMixin";
import VueSlickCarousel from "vue-slick-carousel";
import Progressbar from "../../components/elements/progressbar/Progressbar";
import Timeline from "../../components/elements/timeline/Timeline";
import ServiceFour from "../../components/elements/service/ServiceFour";
//IDC_CDN_PRTFILE_PICTURE
import IDC_CDN_picture from "../../components/elements/portfolio/IDC_CDN_picture.vue";
import IDC_CDN_picture_oneRow from "../../components/elements/portfolio/IDC_CDN_picture_oneRow.vue";
import IDC_CDN_Portfolio_1 from "../../components/elements/portfolio/IDC_CDN_Portfolio_1.vue";
import IDC_CDN_AdvancedTab from "../../components/elements/tab/IDC_CDN_AdvancedTab.vue";
import IDC_CDN_Node from "./IDC_CDN_Node.vue";
import NCTB from "./NCTB.vue";
import IDC_CDN_Gallery from "./IDC_CDN_Gallery.vue";
import cloud_platform from "./cloud_platform.vue";
import Cloud_IAAS from "./cloud_IAAS.vue";
import Cloud_brand from "./cloud_brand.vue";
import Bare_Metal_Platform from "./Bare_Metal_Platform.vue";
import Cloud_PAAS from "./cloud_PAAS.vue";
import cloud_resource_pool from "./cloud_resource_pool.vue";
import Cloud_interconnection_delay_matrix from "./cloud_interconnection_delay_matrix.vue";
import Cloud_AS63199 from "./cloud_AS63199.vue";
import Bar_h from '../../components/echarts/Bar_h';

export default {
    name: 'Startup',
    components: {
        Bar_h,
        Cloud_AS63199,
        Cloud_interconnection_delay_matrix,
        cloud_resource_pool,
        Cloud_PAAS,
        Bare_Metal_Platform,
        Cloud_brand,
        Cloud_IAAS,
        cloud_platform,
        IDC_CDN_Gallery,
        NCTB,
        IDC_CDN_Node,
        IDC_CDN_AdvancedTab,
        IDC_CDN_Portfolio_1,
        IDC_CDN_picture_oneRow,
        IDC_CDN_picture,
        ServiceFour,
        Timeline, Progressbar, BlogPost, SectionTitle, Separator, Layout, VueSlickCarousel
    },
    mixins: [BlogPostMixin],
    data() {
        return {
            sliderSetting: {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: true,
                responsive: [
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 993,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 580,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 481,
                        settings: {
                            slidesToShow: 1,
                        }
                    }
                ]
            },
            sliderData: [
                {
                    image: 'index',
                    title: `IDC - CDN.`,
                    description: `A closer and faster internet for users and APPs`
                },
                {
                    image: 'fiber',
                    title: `Global Internet Links.`,
                    description: ``
                },
                {
                    image: 'planet',
                    title: `NetO global  sites deploy closer to users from new markets.`,
                    description: ``
                }
            ],
            portfolioData_2: [
                {
                    id: 1,
                    portfolioImage: require(`@/assets/images/portfolio/Sites right now.png`),
                    title: ' Former Globalization Internet Network Sites',
                    category: 'Core sites have been concentrated on North America、 Europe、 India 、Japan、Korea、 Singpore and HongKong, but still far from most of the Internet users.',
                    description: 'NetO global sites deploy closer to users from new markets.'
                },
                {
                    id: 2,
                    portfolioImage: require(`@/assets/images/portfolio/Sites in the future.png`),
                },
            ],
            portfolioData: [
                {
                    id: 1,
                    portfolioImage: require(`@/assets/images/portfolio/Infrastructure-1.png`),
                    title: 'NetO Global Network Infrastructure',
                    category: '',

                },
                {
                    id: 2,
                    portfolioImage: require(`@/assets/images/portfolio/Infrastructure-2.png`),
                    title: 'NetO Global Network Infrastructure',
                    category: '',
                },
                {
                    id: 3,
                    portfolioImage: require(`@/assets/images/portfolio/Population.png`),
                    title: 'Variation Trend of Global Population and GDP',
                    category: '',
                },
                {
                    id: 4,
                    portfolioImage: require(`@/assets/images/portfolio/GDP.png`),
                    title: 'Variation Trend of Global Population and GDP',
                    category: '',
                },
            ],
            tabData: {
                tabContent: [
                    {
                        id: 1,
                        menu: 'Cloud-interconnection-delay-matrix',
                        title: 'Cloud interconnection delay matrix',
                        description: '',
                        image: require(`@/assets/images/tab/delay-matrix.jpg`),
                    },
                    {
                        id: 2,
                        menu: 'Overseas-autonomous-region-information-AS63199',
                        title: 'Overseas autonomous region information AS63199',
                        description: '',
                        image: require(`@/assets/images/tab/information-AS63199.jpg`),
                    },
                    {
                        id: 3,
                        menu: 'Introduction-to-the-Public-Network',
                        title: 'Introduction to the Public Network',
                        description: '',
                        image: require(`@/assets/images/tab/Public-Network.jpg`),
                    }
                ]
            },
            galleryData: [
                {
                    id: 1,
                    thumb: require(`@/assets/images/cloud/cloud-node-architecture.jpg`),
                    src: require(`@/assets/images/cloud/cloud-node-architecture.jpg`),
                    type: 'image'
                },
                {
                    id: 2,
                    thumb: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                    src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk',
                    type: 'image'
                },
                {
                    id: 3,
                    thumb: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                    src: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                    type: 'image'
                },

            ],
            brandList4: [
                {
                    image: require(`@/assets/images/cloud/brand_1.png`)
                },
                {
                    image: require(`@/assets/images/cloud/brand_2.png`)
                },
                {
                    image: require(`@/assets/images/cloud/brand_3.png`)
                },
                {
                    image: require(`@/assets/images/cloud/brand_4.png`)
                },
                {
                    image: require(`@/assets/images/cloud/brand_5.png`)
                },
            ],
            bar_h: {
                xAxisData: [
                    "Chinese mainland",
                    "North America",
                    "Europe",
                    "The Middle East",
                    "Southeast Asia",
                    "India",
                    "South America",
                    "North America"
                ],
                yAxisData: [
                    31.5,
                    88.5,
                    30.5,
                    92.0,
                    28.5,
                    46.0,
                    67.0,
                    53.5
                ]
            }
        }
    }
}
</script>