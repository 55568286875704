import { render, staticRenderFns } from "./cloud_resource_pool.vue?vue&type=template&id=483bc384&scoped=true"
import script from "./cloud_resource_pool.vue?vue&type=script&lang=js"
export * from "./cloud_resource_pool.vue?vue&type=script&lang=js"
import style0 from "./cloud_resource_pool.vue?vue&type=style&index=0&id=483bc384&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "483bc384",
  null
  
)

export default component.exports