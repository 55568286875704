<template>
    <div class="row row--15 service-wrapper">
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
             v-for="(service, index) in serviceList"
             :key=index>
            <div :class="`card-box ${cardStyle} ${checkTextAlign}`"
                 data-aos="fade-up"
                 data-aos-delay="70">
                <div class="inner">
                    <div class="image">
                        <!-- <router-link to="/service-details"> -->
                            <img :src="service.image" alt="card Images"/>
                        <!-- </router-link> -->
                    </div>
                    <div class="content">
                        <h4 class="title mb--20">
                            <!-- <router-link to="/service-details" v-html="service.title"/> -->
                            {{ service.title }}
                        </h4>
                        <p class="description b1 color-gray mb--0" v-html="service.description"/>
                        <!-- <router-link class="btn-default btn-small btn-border" to="/service-details">
                            Read More
                        </router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServiceTwo',
        props: {
            cardStyle: {
                type: String
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        data() {
            return {
                serviceList: [
                    {
                        image: require('@/assets/images/service/carrier.png'),
                        title: 'For Carriers',
                        description: 'Providing more direct high-speed links and complete applications.'
                    },
                    {
                        image: require('@/assets/images/service/ISP.png'),
                        title: 'For ISPs',
                        description: "Extending more connections and services for the users' prefect experiences."
                    },
                    {
                        image: require('@/assets/images/service/app.png'),
                        title: 'For APPs',
                        description: 'Supporting APPs Closer, wider, faster connection to more and more users.'
                    },
                    // {
                    //     image: require('@/assets/images/service/user.png'),
                    //     title: 'For Users',
                    //     description: ' Improve user experience with robust data insights and automation.'
                    // }
                ]
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>