<template>
  <div class="nctb-container">
    <table class="connectivity-matrix">
      <thead>
        <tr>
          <th rowspan="2"></th>
          <th rowspan="2"></th>
          <th colspan="3">Singapore</th>
          <th colspan="2">Indonesia</th>
          <th>Malaysia</th>
          <th colspan="2">Hong Kong</th>
          <th colspan="2">Japan</th>
          <th>South Korea</th>
          <th colspan="2">Dallas</th>
          <th colspan="2">Frankfurt</th>
        </tr>
        <tr>
          <th>Equinix EIE</th>
          <th>BBIX</th>
          <th>SGIX</th>
          <th>JKT-IX</th>
          <th>IIX-Jakarta</th>
          <th>MyIX</th>
          <th>HKIX</th>
          <th>Equinix EIE</th>
          <th>Equinix EIE</th>
          <th>JPIX TOKYO</th>
          <th>KINX</th>
          <th>DE-CIX</th>
          <th>Equinix EIE</th>
          <th>DE-CIX</th>
          <th>Equinix EIE</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(provider, index) in providers" :key="index">
          <td v-if="isFirstInCategory(index)" :rowspan="getCategoryRowspan(provider.type)">
            {{ provider.type }}
          </td>
          <td>{{ provider.name }}</td>
          <td v-for="(connected, connIndex) in provider.connections" :key="connIndex">
            <span :class="{ 'connected': connected }">
              {{ connected ? '✓' : '✗' }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'NCTB',
  data() {
    return {
      providers: [
        {
          type: 'Cloud service provider',
          name: 'Google AS15169',
          connections: [true, true, true, false, false, true, true, true, true, true, true, true, true, true, true]
        },
        {
          type: 'Cloud service provider',
          name: 'Amazon AS16509',
          connections: [true, true, true, false, false, true, true, true, true, true, true, true, true, true, false]
        },
        {
          type: 'Cloud service provider',
          name: 'Microsoft AS8075',
          connections: [true, true, true, false, false, true, true, true, true, true, true, true, true, true, true]
        },
        {
          type: 'Cloud service provider',
          name: 'Zenlayer AS21859',
          connections: [true, true, true, false, false, true, true, true, false, true, true, false, true, true, true]
        },
        {
          type: 'Cloud service provider',
          name: 'Huawei Cloud AS136907',
          connections: [true, true, true, false, false, false, true, true, false, false, false, false, false, false, false]
        },
        {
          type: 'Cloud service provider',
          name: 'DigitalOcean AS14061',
          connections: [true, false, true, false, false, false, false, false, false, false, false, false, false, true, false]
        },
        {
          type: 'Cloud service provider',
          name: 'Enjoyvs AS136897',
          connections: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
        },
        {
          type: 'Cloud service provider',
          name: 'Softlayer AS36351',
          connections: [true, true, false, false, false, false, true, true, true, false, true, true, true, true, false]
        },
        {
          type: 'Cloud service provider',
          name: 'OVH AS16276',
          connections: [true, true, true, false, false, false, false, false, false, false, false, false, true, true, false]
        },
        {
          type: 'Cloud service provider',
          name: 'Fastly AS54113',
          connections: [true, false, true, false, false, false, false, true, true, true, false, false, false, false, false]
        },
        {
          type: 'Cloud service provider',
          name: 'Kingsoft Cloud AS137280',
          connections: [true, false, false, false, false, false, true, true, false, false, false, false, false, false, false]
        },
        {
          type: 'Cloud service provider',
          name: 'Salesforce AS14340',
          connections: [false, false, false, false, false, false, false, false, false, true, false, true, true, true, true]
        },
        {
          type: 'Cloud service provider',
          name: 'Alibaba cloud AS45102',
          connections: [true, true, true, false, true, true, true, true, false, false, true, false, false, true, false]
        },
        {
          type: 'Cloud service provider',
          name: 'Alibaba China AS37963',
          connections: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
        },
        {
          type: 'Cloud service provider',
          name: 'Tencent AS132203',
          connections: [true, true, true, false, false, false, true, true, false, false, true, false, false, true, false]
        },
        {
          type: 'Cloud service provider',
          name: 'Tencent China AS45090',
          connections: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
        },
        {
          type: 'Cloud service provider',
          name: 'Ucloud AS135377',
          connections: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
        },
        {
          type: 'CDN',
          name: 'I3D AS49544',
          connections: [true, true, true, false, false, false, true, true, true, true, false, true, true, true, false]
        },
        {
          type: 'CDN',
          name: 'Akamai AS20940',
          connections: [true, false, false, true, true, true, true, true, true, true, true, true, true, true, false]
        },
        {
          type: 'CDN',
          name: 'Cloudflare AS13335',
          connections: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true]
        },
        {
          type: 'CDN',
          name: 'Alibaba CDN AS24429',
          connections: [true, false, false, false, false, true, false, true, false, true, false, false, false, true, false]
        },
        {
          type: 'CDN',
          name: 'EdgeCast AS15133',
          connections: [true, true, true, false, false, false, false, true, true, true, false, true, true, true, true]
        },
        {
          type: 'CDN',
          name: 'Bigo AS10122',
          connections: [true, false, false, false, false, false, true, true, false, false, false, false, false, true, false]
        },
        {
          type: 'CDN',
          name: 'Limelight AS22822',
          connections: [true, true, false, false, false, true, true, true, true, true, false, true, true, true, false]
        },
        {
          type: 'OTT',
          name: 'Apple AS714',
          connections: [true, false, true, false, false, false, false, true, true, true, true, true, true, true, true]
        },
        {
          type: 'OTT',
          name: 'Facebook AS32934',
          connections: [true, true, true, true, true, true, true, true, true, true, false, false, true, true, true]
        },
        {
          type: 'OTT',
          name: 'Never AS35354 (Korea)',
          connections: [true, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
        },
        {
          type: 'OTT',
          name: 'Vatiav AS32590',
          connections: [true, false, true, false, false, false, true, true, true, true, false, false, true, true, false]
        },
        {
          type: 'OTT',
          name: 'Netflix AS2906',
          connections: [true, true, true, false, false, true, true, true, true, true, false, false, true, true, true]
        }
      ]
    }
  },
  methods: {
    isFirstInCategory(index) {
      return index === 0 || this.providers[index].type !== this.providers[index - 1].type
    },
    getCategoryRowspan(type) {
      return this.providers.filter(provider => provider.type === type).length
    }
  }
}
</script>

<style scoped>
.nctb-container {
  padding: 20px;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.connectivity-matrix {
  border-collapse: collapse;
  width: 100%;
  background: white;
  min-width: 1200px;
}

.connectivity-matrix th,
.connectivity-matrix td {
  border: 1px solid #000000;
  padding: 8px;
  text-align: center;
}

.connectivity-matrix th {
  background-color: #4a9ea3;
  color: white;
}

.connected {
  background-color: #800000;
  color: white;
  margin: -8px;
  padding: 8px;
  display: block;
}

tbody tr:nth-child(even) {
  /* background-color: #f9f9f9; */
  
}

tbody tr:hover {
  /* background-color: #f5f5f5; */
}

/* 保持表格标题的原有颜色 */
tr th {
    /* 保持原有样式 */
}

/* 将其他所有文字改为黑色 */
tr td {
    color: #000000;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .nctb-container {
    padding: 10px;
  }

  .connectivity-matrix th,
  .connectivity-matrix td {
    padding: 4px;
    font-size: 14px;
  }

  .connected {
    margin: -4px;
    padding: 4px;
  }
}
</style>
