// src/utils/lazy/lazy.js
// 需要使用v-lazy指令
// export default {
//   install(Vue) {
//     Vue.directive('lazy', {
//       bind(el) {
//         console.log("🚀 ~ bind ~ bind:", el.src);

//         // 保存图片的原始src
//         el._imgSrc = el.src;
//         el.src = ''; // 清空src以防止图片在进入视口前加载
//       },
//       inserted(el) {
//         console.log("🚀 ~ inserted ~ inserted:", el.src);

//         // 创建IntersectionObserver实例
//         el._observer = new IntersectionObserver(([{ isIntersecting }]) => {
//           if (isIntersecting) {
//             el.src = el._imgSrc; // 设置图片src以开始加载
//             el._observer.unobserve(el); // 停止观察
//           }
//         });
//         el._observer.observe(el); // 开始观察
//       },
//       unbind(el) {
//         console.log("🚀 ~ unbind ~ unbind:", el.src);

//         // 清理Observer
//         if (el._observer) {
//           el._observer.unobserve(el);
//           el._observer = null;
//         }
//       }
//     });
//   }
// };
// src/utils/lazy/lazy.js
// 不需要v-lazy指令
export default {
  install(Vue) {
    // 创建一个全局指令
    Vue.directive('lazy', {
      bind(el) {
        el._imgSrc = el.src;
        el.src = ''; 
      },
      inserted(el) {
        const observer = new IntersectionObserver(([{ isIntersecting }]) => {
          if (isIntersecting) {
            el.src = el._imgSrc;
            observer.unobserve(el);
          }
        });
        observer.observe(el);
        el._observer = observer;
      },
      unbind(el) {
        if (el._observer) {
          el._observer.unobserve(el);
          el._observer = null;
        }
      }
    });

    // 重写 Vue 的 img 组件
    const originalImg = Vue.component('img') || 'img';
    Vue.component('img', {
      extends: originalImg,
      mounted() {
        const el = this.$el;
        // 应用懒加载逻辑
        el._imgSrc = el.src;
        el.src = '';

        const observer = new IntersectionObserver(([{ isIntersecting }]) => {
          if (isIntersecting) {
            el.src = el._imgSrc;
            observer.unobserve(el);
          }
        });
        
        observer.observe(el);
        el._observer = observer;
      },
      beforeDestroy() {
        const el = this.$el;
        if (el._observer) {
          el._observer.unobserve(el);
          el._observer = null;
        }
      }
    });
  }
};
