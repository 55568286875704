<template>
    <div class="row row--15 service-wrapper">
        <div class="col-lg-4 col-md-6 col-sm-6 col-12 " v-for="(service, index) in serviceList" :key=index>
            <div class="service-wrapper" :class="`service ${serviceStyle} ${checkTextAlign}`" data-aos="fade-up"
                data-aos-delay="70">
                <div class="icon">
                    <img :src="service.image" alt="live-streaming Images" />
                </div>
                <div class="content">
                    <h4 class="title w-600" :class="index % 2 === 0 ? 'red-title' : 'blue-title'">
                        {{ service.title }}
                    </h4>

                    <div class="description b1 color-gray mb--0">
                        <ul>
                            <li v-for="(item, index) in service.description" :key="index"
                                style="list-style-type: disc;">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '../../components/icon/Icon.vue'

export default {
    name: 'ServiceOne',
    components: { Icon },
    props: {
        serviceStyle: {
            type: String
        },
        iconSize: {
            type: String | Number,
            default: 62
        },
        textAlign: {
            type: String,
            default: 'left'
        }
    },
    data() {
        return {
            serviceList: [
                {
                    image: require('@/assets/images/cloud/platform_1.png'),
                    title: 'GIC Cloud platform',
                    description: [
                        'One-stop IaaS and PaaS service platform.',
                        'Basic virtualization management platform.',
                        'Integrated management of 18 global nodes and nearly 30 data centers.',
                    ]
                },
                {
                    image: require('@/assets/images/cloud/platform_5.png'),
                    title: 'Data product',
                    description: [
                        'Big data computing.',
                        'RDS MySQL(Cluster、Master-slave).',
                        'Redis(Cluster、Master-slave).',
                        'MongoDB.',
                        'Kafka.',
                        'ELK.',
                    ]
                },
                {
                    image: require('@/assets/images/cloud/platform_3.png'),
                    title: 'IaaS Product',
                    description: [
                        'Bare metal.',
                        'Managed private cloud.',
                        'Cloud server',
                        'Container',
                        'Block storage',
                        'Object storage',
                        'Cold storage',
                    ]
                },
                {
                    image: require('@/assets/images/cloud/platform_4.png'),
                    title: 'Network product',
                    description: [
                        'Dedicated transmission line.',
                        'Cloud interconnect backbone.',
                        'Global optimized bandwidth.',
                        'IPV6.',
                        'Global synchronization.',
                        'Multi-cloud interconnect.',
                        'SD-WAN.',
                        'CDN.',
                        'Domain name resolution.',
                        'Load balancing.',
                    ]
                },
                
                {
                    image: require('@/assets/images/cloud/platform_6.png'),
                    title: 'Security product',
                    description: [
                        'Security suite.',
                        'Ddos protection.',
                        'Dynamic authentication.',
                        'High-defenseIP、SSL certificate.',
                    ]
                },
                {
                    image: require('@/assets/images/cloud/platform_2.png'),
                    title: 'IDC Product',
                    description: [
                        'Rack.',
                        'Bandwidth.',
                        'Virtual and physical integration.',
                    ]
                },
            ]
        }
    },
    computed: {
        checkTextAlign() {
            if (this.textAlign === 'left') return 'text-start';
            else if (this.textAlign === 'center') return 'text-center';
            else if (this.textAlign === 'end') return 'text-end';
            else return '';
        }
    }
}
</script>
<style scoped>
.service-wrapper {
    display: flex;
    /* align-items: flex-start; */
    
    height: 100%;
}
.service.service__style--1.text-start{
    padding: 20px 50px ;

}
.col-lg-4 {
    margin-bottom: 30px;
    display: flex;
}

.service {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.service-wrapper .icon {
    margin-right: 20px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.service-wrapper .content {
    height: 100%;
    flex: 1;
}

.service-wrapper .content h4 {
    display: inline-block;
    vertical-align: middle;
    margin-top: 15px;
}

.service-wrapper .content .description {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.red-title {
    color: rgb(198, 25, 46);
}

.blue-title {
    color: rgb(55, 138, 165);
}

</style>