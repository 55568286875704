<template>
    <div :class="`rn-accordion-style ${accordionStyleClass} accordion`">
        <div class="accordion" :id="id">
            <div class="accordion-item card" v-for="(item, index) in accordionContent">
                <h2 class="accordion-header card-header" :id="`heading${item.id}`">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" style="font-weight: bold;font-size: 20px;"
                        :data-bs-target="`#collapse${item.id}`"
                        :aria-expanded="index === activeCollapse ? 'true' : 'false'"
                        :aria-controls="`collapse${item.id}`" @click="activeCollapse = index">
                        {{ item.title }}
                        <i class="collapse-icon" />
                    </button>
                </h2>
                <div :id="`collapse${item.id}`" class="accordion-collapse collapse" :class="{ 'show': index === 0 }"
                    :aria-labelledby="`heading${item.id}`" :data-bs-parent="`#${id}`">
                    <div class="accordion-body card-body" style="padding: 10px 30px 10px 50px;" v-for="(i, index) in item.subtitle">
                        <ul>
                            <li style="list-style-type: disc;margin-bottom: 0px;font-weight: bold;">
                                {{ i }}
                            </li>
                            <li style="list-style-type: none;margin-top: 0px;">
                                {{ item.description[index] }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Accordion',
    props: {
        id: {
            type: String,
            default: 'accordionExample'
        },
        accordionStyleClass: {
            type: String,
            // default:'.rn-accordion-style .card'
            // .rn-accordion-style.rn-accordion-02 .card
            // .rn-accordion-style.rn-accordion-03 .card
            // .rn-accordion-style.rn-accordion-04 .card',
        },
        accordionContent: {
            type: Array,
            default: function () {
                return [
                    {
                        id: 'One',
                        title: 'Senior Management Team',
                        subtitle: [
                            'Chief Executive Officer (CEO)',
                            'Chief Operating Officer (COO)',
                            'Chief Technology Officer (CTO)',
                            'Chief Financial Officer (CFO)',
                        ],
                        description: [
                            'Responsible for the company\'s overall strategy, setting objectives, and overseeing execution.',
                            'Responsible for managing daily operations and ensuring efficient collaboration across departments.',
                            'Responsible for the technology strategy and managing the technical team to ensure the efficient operation of network infrastructure and services.',
                            'Responsible for financial planning, budget management, and profitability analysis.',
                        ]
                    },
                    {
                        id: 'Two',
                        title: 'Technology and Network Team',
                        subtitle: [
                            'Network Architecture and Design Team',
                            'Network Operations and Support Team',
                            'Security and Compliance Team',
                            'Research and Development (R&D) Team',
                        ],
                        description: [
                            'Responsible for designing and optimizing network infrastructure, including fiber optic networks, data centers, and edge computing architectures.',
                            'Responsible for the daily maintenance, monitoring, and troubleshooting of the network, ensuring high service availability.',
                            'Responsible for network security, privacy protection, and compliance with industry regulations (such as FCC standards).',
                            'Responsible for developing new technologies, such as high-speed network protocols and IoT (Internet of Things) support.',
                        ]
                    },
                    {
                        id: 'Three',
                        title: 'Customer Service and Support Team',
                        subtitle: [
                            'Customer Support Center',
                            'On-site Technical Support Team',
                        ],
                        description: [
                            'Provides 24/7 customer support, resolving user issues via phone and email.',
                            'Responsible for handling on-site equipment installation, maintenance, and upgrades for customers.',
                        ]
                    },
                    {
                        id: 'Four',
                        title: 'Marketing and Sales Team',
                        subtitle: [
                            'Market Analysis and Strategy Team',
                            'Corporate Sales Team',
                        ],
                        description: [
                            'Responsible for conducting market research, performing competitive analysis, and formulating brand strategies.',
                            'Focuses on collaborating with corporate clients and selling customized services.',
                        ]
                    },
                    {
                        id: 'five',
                        title: 'Finance and Administrative Support Team',
                        subtitle: [
                            'Financial Management Team',
                            'Human Resources Team',
                            'Legal and Compliance Team',
                        ],
                        description: [
                            'Responsible for budgeting, auditing, taxation, and financial analysis.',
                            'Responsible for recruitment, employee training, and fostering corporate culture.',
                            'Ensures business operations comply with local laws and regulations, and is responsible for handling contracts and disputes.',
                        ]
                    }
                ]
            }
        }
    },
    data() {
        return {
            activeCollapse: 0
        }
    }
}
</script>
