import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/home-pages/index.vue'
import IDC_CDN from '../views/home-pages/IDC_CDN.vue'
import About from '../views/About.vue'
import ContactPage from '../views/ContactPage.vue'
import SecurityWaf from '../views/home-pages/SecurityWaf.vue'
import SecurityDDoS from '../views/home-pages/SecurityDDoS.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import Live_Streaming from '../views/Live_Streaming.vue'

// 

// import ButtonPage from '../views/ButtonPage'
// import ServicePage from '../views/ServicePage.vue'
// import ServiceDetailsPage from '../views/ServiceDetailsPage'
// import CounterUpPage from '../views/CounterUpPage'
// import ProgressbarPage from '../views/ProgressbarPage'
// import AccordionPage from '../views/AccordionPage'
// import SocialSharePage from '../views/SocialSharePage'
// import BlogGridPage from '../views/BlogGridPage'
// import BlogGridSidebarPage from '../views/BlogGridSidebarPage'
// import BlogListPage from '../views/BlogListPage'
// import BlogListSidebarPage from '../views/BlogListSidebarPage'
// import BlogDetailsPage from '../views/BlogDetailsPage'
// import BlogCategoryPosts from '../components/blog/BlogCategoryPosts'
// import TeamPage from '../views/TeamPage'
// import PortfolioPage from '../views/PortfolioPage'
// import PortfolioThreeColumnPage from '../views/PortfolioThreeColumnPage'
// import PortfolioFullWidthPage from '../views/PortfolioFullWidthPage'
// import PortfolioGridLayoutPage from '../views/PortfolioGridLayoutPage'
// import PortfolioBoxLayoutPage from '../views/PortfolioBoxLayoutPage'
// import PortfolioDetailsPage from '../views/PortfolioDetailsPage'
// import TestimonialPage from '../views/TestimonialPage'
// import TimelinePage from '../views/TimelinePage'
// import TabPage from '../views/TabPage'
// import PricingPage from '../views/PricingPage'
// import SplitSectionPage from '../views/SplitSectionPage'
// import CallToActionPage from '../views/CallToActionPage'
// import VideoPage from '../views/VideoPage'
// import GalleryPage from '../views/GalleryPage'
// import BrandPage from '../views/BrandPage'
// import ErrorPage from '../views/404'
// import AdvanceTabPage from '../views/AdvanceTabPage'
// import BrandCarouselPage from '../views/BrandCarouselPage'
// import AdvancePricingPage from '../views/AdvancePricingPage'
// import BusinessConsulting from '../views/home-pages/BusinessConsulting'
// import BusinessConsulting2 from '../views/home-pages/BusinessConsulting2'
// import Business from '../views/home-pages/Business'
// import DigitalAgency from '../views/home-pages/DigitalAgency'
// import Finance from '../views/home-pages/Finance'
// import Company from '../views/home-pages/Company'
// import MarketAgency from '../views/home-pages/MarketAgency'
// import TravelAgency from '../views/home-pages/TravelAgency'
// import Consulting from '../views/home-pages/Consulting'
// import SeoAgency from '../views/home-pages/SeoAgency'
// import PersonalPortfolio from '../views/home-pages/PersonalPortfolio'
// import EventConference from '../views/home-pages/EventConference'
// import CreativePortfolio from '../views/home-pages/CreativePortfolio'
// import Freelancer from '../views/home-pages/Freelancer'
// import InternationalConsulting from '../views/home-pages/InternationalConsulting'
// import Startup from '../views/home-pages/Startup'
// import WebAgency from '../views/home-pages/WebAgency'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: index,
        meta: {
            title: 'NetO',
        },
    },
    {
        path: '/index',
        name: 'index',
        component: index,
        meta: {
            title: 'NetO',
        },
        redirect: '/'
     },
     {
        path: '/IDC-CDN',
        name: 'IDC-CDN',
        component: IDC_CDN,
        meta: {
            title: 'NetO IDC&CDN',
        },
    },
    {
        path: '/Live-Streaming',
        name: 'Live-Streaming',
        component: Live_Streaming,
        meta: {
            title: 'NetO Live Streaming',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: 'About NETO',
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: ContactPage,
        meta: {
            title: 'Contact NetO',
        },
    },
    {
        path: '/security-waf',
        name: 'Security Waf',
        component: SecurityWaf,
        meta: {
            title: 'NETO Cloud Waf Security',
        },
    },
    {
        path: '/security-ddos',
        name: 'Security DDoS',
        component: SecurityDDoS,
        meta: {
            title: 'NETO Cloud DDoS Security',
        },
    },
    {
        path: '/Privacy-Policy',
        name: 'Privacy Policy',
        component: PrivacyPolicy,
        meta: {
            title: 'NETO Privacy Policy',
        },
    },

    //-----------------------------------------------------------------
    // {
    //     path: '/button',
    //     name: 'Button',
    //     component: ButtonPage,
    //     meta: {
    //         title: 'Button Page',
    //     },
    // },
    // {
    //     path: '/service',
    //     name: 'Service',
    //     component: ServicePage,
    //     meta: {
    //         title: 'Service Page',
    //     },
    // },
    // {
    //     path: '/service-details',
    //     name: 'ServiceDetails',
    //     component: ServiceDetailsPage,
    //     meta: {
    //         title: 'Service Details Page',
    //     },
    // },
    // {
    //     path: '/counter-up',
    //     name: 'CounterUp',
    //     component: CounterUpPage,
    //     meta: {
    //         title: 'Counter Up Page',
    //     },
    // },
    // {
    //     path: '/progressbar',
    //     name: 'Progressbar',
    //     component: ProgressbarPage,
    //     meta: {
    //         title: 'Progress Bar Page',
    //     },
    // },
    // {
    //     path: '/accordion',
    //     name: 'Accordion',
    //     component: AccordionPage,
    //     meta: {
    //         title: 'Accordion Page',
    //     },
    // },
    // {
    //     path: '/social-share',
    //     name: 'SocialShare',
    //     component: SocialSharePage,
    //     meta: {
    //         title: 'Social Share Page',
    //     },
    // },
    // {
    //     path: '/blog-grid',
    //     name: 'BlogGrid',
    //     component: BlogGridPage,
    //     meta: {
    //         title: 'Blog Grid Page',
    //     },
    // },
    // {
    //     path: '/blog-grid-sidebar',
    //     name: 'BlogGridSidebar',
    //     component: BlogGridSidebarPage,
    //     meta: {
    //         title: 'Blog Grid Sidebar Page',
    //     },
    // },
    // {
    //     path: '/blog-list',
    //     name: 'BlogList',
    //     component: BlogListPage,
    //     meta: {
    //         title: 'Blog List Page',
    //     },
    // },
    // {
    //     path: '/blog-list-sidebar',
    //     name: 'BlogListSidebar',
    //     component: BlogListSidebarPage,
    //     meta: {
    //         title: 'Blog List Sidebar Page',
    //     },
    // },
    // {
    //     path: '/blog-details',
    //     name: 'BlogDetails',
    //     component: BlogDetailsPage,
    //     meta: {
    //         title: 'Blog Details Page',
    //     },
    // },
    // {
    //     path: '/blog-category-posts',
    //     name: 'BlogCategoryPosts',
    //     component: BlogCategoryPosts,
    //     meta: {
    //         title: 'Blog Category Posts',
    //     },
    // },
    // {
    //     path: '/team',
    //     name: 'Team',
    //     component: TeamPage,
    //     meta: {
    //         title: 'Team Page',
    //     },
    // },
    // {
    //     path: '/portfolio',
    //     name: 'Portfolio',
    //     component: PortfolioPage,
    //     meta: {
    //         title: 'Portfolio Page',
    //     },
    // },
    // {
    //     path: '/portfolio-three-column',
    //     name: 'PortfolioThreeColumn',
    //     component: PortfolioThreeColumnPage,
    //     meta: {
    //         title: 'Portfolio Three Column Page',
    //     },
    // },
    // {
    //     path: '/portfolio-full-width',
    //     name: 'PortfolioFullWidth',
    //     component: PortfolioFullWidthPage,
    //     meta: {
    //         title: 'Portfolio Full Width Page',
    //     },
    // },
    // {
    //     path: '/portfolio-grid-layout',
    //     name: 'PortfolioGridLayout',
    //     component: PortfolioGridLayoutPage,
    //     meta: {
    //         title: 'Portfolio Grid Layout Page',
    //     },
    // },
    // {
    //     path: '/portfolio-box-layout',
    //     name: 'PortfolioBoxLayout',
    //     component: PortfolioBoxLayoutPage,
    //     meta: {
    //         title: 'Portfolio Box Layout Page',
    //     },
    // },
    // {
    //     path: '/portfolio-details',
    //     name: 'PortfolioDetails',
    //     component: PortfolioDetailsPage,
    //     meta: {
    //         title: 'Portfolio Details Page',
    //     },
    // },
    // {
    //     path: '/testimonial',
    //     name: 'Testimonial',
    //     component: TestimonialPage,
    //     meta: {
    //         title: 'Testimonial Page',
    //     },
    // },
    // {
    //     path: '/timeline',
    //     name: 'Timeline',
    //     component: TimelinePage,
    //     meta: {
    //         title: 'Timeline Page',
    //     },
    // },
    // {
    //     path: '/tab',
    //     name: 'Tab',
    //     component: TabPage,
    //     meta: {
    //         title: 'Tab Page',
    //     },
    // },
    // {
    //     path: '/pricing',
    //     name: 'Pricing',
    //     component: PricingPage,
    //     meta: {
    //         title: 'Pricing Page',
    //     },
    // },
    // {
    //     path: '/split-section',
    //     name: 'SplitSection',
    //     component: SplitSectionPage,
    //     meta: {
    //         title: 'Split Section Page',
    //     },
    // },
    // {
    //     path: '/call-to-action',
    //     name: 'CallToAction',
    //     component: CallToActionPage,
    //     meta: {
    //         title: 'Call To Action Page',
    //     },
    // },
    // {
    //     path: '/video',
    //     name: 'Video',
    //     component: VideoPage,
    //     meta: {
    //         title: 'Video Page',
    //     },
    // },
    // {
    //     path: '/gallery',
    //     name: 'Gallery',
    //     component: GalleryPage,
    //     meta: {
    //         title: 'Gallery Page',
    //     },
    // },
    // {
    //     path: '/brand',
    //     name: 'Brand',
    //     component: BrandPage,
    //     meta: {
    //         title: 'Brand Page',
    //     },
    // },
    // {
    //     path: '/404',
    //     name: 'Error',
    //     component: ErrorPage,
    //     meta: {
    //         title: '404 Not Found',
    //     },
    // },
    // {
    //     path: '/advance-tab',
    //     name: 'AdvanceTab',
    //     component: AdvanceTabPage,
    //     meta: {
    //         title: 'Advance Tab Page',
    //     },
    // },
    // {
    //     path: '/brand-carousel',
    //     name: 'BrandCarousel',
    //     component: BrandCarouselPage,
    //     meta: {
    //         title: 'Brand Carousel Page',
    //     },
    // },
    // {
    //     path: '/advance-pricing',
    //     name: 'AdvancePricing',
    //     component: AdvancePricingPage,
    //     meta: {
    //         title: 'Advance Pricing Page',
    //     },
    // },
    // {
    //     path: '/business-consulting',
    //     name: 'BusinessConsulting',
    //     component: BusinessConsulting,
    //     meta: {
    //         title: 'Business Consulting Page',
    //     },
    // },
    // {
    //     path: '/business-consulting2',
    //     name: 'BusinessConsulting2',
    //     component: BusinessConsulting2,
    //     meta: {
    //         title: 'Business Consulting 2 Page',
    //     },
    // },
    // {
    //     path: '/business',
    //     name: 'Business',
    //     component: Business,
    //     meta: {
    //         title: 'Business Page',
    //     },
    // },
    // {
    //     path: '/digital-agency',
    //     name: 'DigitalAgency',
    //     component: DigitalAgency,
    //     meta: {
    //         title: 'Digital Agency Page',
    //     },
    // },
    // {
    //     path: '/finance',
    //     name: 'Finance',
    //     component: Finance,
    //     meta: {
    //         title: 'Finance Page',
    //     },
    // },
    // {
    //     path: '/company',
    //     name: 'Company',
    //     component: Company,
    //     meta: {
    //         title: 'Company Page',
    //     },
    // },
    // {
    //     path: '/market-agency',
    //     name: 'MarketAgency',
    //     component: MarketAgency,
    //     meta: {
    //         title: 'Market Agency Page',
    //     },
    // },
    // {
    //     path: '/travel-agency',
    //     name: 'TravelAgency',
    //     component: TravelAgency,
    //     meta: {
    //         title: 'Travel Agency Page',
    //     },
    // },
    // {
    //     path: '/consulting',
    //     name: 'Consulting',
    //     component: Consulting,
    //     meta: {
    //         title: 'Consulting Page',
    //     },
    // },
    // {
    //     path: '/seo-agency',
    //     name: 'SeoAgency',
    //     component: SeoAgency,
    //     meta: {
    //         title: 'SEO Agency Page',
    //     },
    // },
    // {
    //     path: '/personal-portfolio',
    //     name: 'PersonalPortfolio',
    //     component: PersonalPortfolio,
    //     meta: {
    //         title: 'Personal Portfolio Page',
    //     },
    // },
    // {
    //     path: '/event-conference',
    //     name: 'EventConference',
    //     component: EventConference,
    //     meta: {
    //         title: 'Event Conference Page',
    //     },
    // },
    // {
    //     path: '/creative-portfolio',
    //     name: 'CreativePortfolio',
    //     component: CreativePortfolio,
    //     meta: {
    //         title: 'Creative Portfolio Page',
    //     },
    // },
    // {
    //     path: '/freelancer',
    //     name: 'Freelancer',
    //     component: Freelancer,
    //     meta: {
    //         title: 'Freelancer Page',
    //     },
    // },
    // {
    //     path: '/international-consulting',
    //     name: 'InternationalConsulting',
    //     component: InternationalConsulting,
    //     meta: {
    //         title: 'International Consulting Page',
    //     },
    // },
    // {
    //     path: '/startup',
    //     name: 'Startup',
    //     component: Startup,
    //     meta: {
    //         title: 'Startup Page',
    //     },
    // },
    // {
    //     path: '/web-agency',
    //     name: 'WebAgency',
    //     component: WebAgency,
    //     meta: {
    //         title: 'Web Agency Page',
    //     },
    // },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
