<template>
    <div class="row row--15 service-wrapper" >
        <div class="col-lg-12 col-md-6 col-sm-6 col-12 " v-for="(service, index) in serviceList" :key=index >
            <div class="service-wrapper" :class="`service ${serviceStyle} ${checkTextAlign}`" data-aos="fade-up"
                data-aos-delay="70" >
                <div class="icon">
                    <img :src="service.image" alt="Images" />
                </div>
                <div class="content" >
                    <h4 class="title w-600" :class="index % 2 === 0 ? 'red-title' : 'blue-title'">
                        {{ service.title }}
                    </h4>

                    <div class="description b1 color-gray mb--0">
                        <ul>
                            <li v-for="(item, index) in service.description" :key="index"
                                style="list-style-type: disc;">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '../../components/icon/Icon.vue'

export default {
    name: 'ServiceOne',
    components: { Icon },
    props: {
        serviceStyle: {
            type: String
        },
        iconSize: {
            type: String | Number,
            default: 62
        },
        textAlign: {
            type: String,
            default: 'left'
        }
    },
    data() {
        return {
            serviceList: [
                {
                    image: require('@/assets/images/cloud/IAAS_1.png'),
                    title: 'Server',
                    description: [
                        'Global renowned brand Dell、Inspur.',
                        'Intel Gold/Platinum series commercial use CPU(Cascade Lake/Ice Lake) Xeon 6240R/6246R/8358P.',
                        'High-performance, highly stable virtualization platform VMware vSphere.',
                    ]
                },
                {
                    image: require('@/assets/images/cloud/IAAS_2.png'),
                    title: 'Storage',
                    description: [
                        'Distributed storage cluster based on EMC all-SSD, providing low-latency, high-IOPS cloud disk storage.',
                        'Offers a variety of products including capacity, high-performance, and ultra-high IO types as needed.',
                        'Meets requirements for data storage, big data computing, high-performance databases, and more.',
                        'All storage products feature redundant design and elastic scalability to ensure data safety, with reliability reaching 8 nines.',
                    ]
                },
                {
                    image: require('@/assets/images/cloud/IAAS_3.png'),
                    title: 'Network',
                    description: [
                        'Telecom-grade core router Cisco ASR、Juniper MX.',
                        '25G/100G Telecom-grade core router CLOS Network Cisco Nexus、Juniper QFX.',
                        'High throughput, high IO, low latency, network isolation',
                    ]
                },
                
            ]
        }
    },
    computed: {
        checkTextAlign() {
            if (this.textAlign === 'left') return 'text-start';
            else if (this.textAlign === 'center') return 'text-center';
            else if (this.textAlign === 'end') return 'text-end';
            else return '';
        }
    }
}
</script>
<style scoped>
.service-wrapper {
    display: flex;
    /* align-items: flex-start; */
    
    height: 100%;
}
.service.service__style--1.text-start{
    padding: 20px 50px ;

}
.col-lg-4 {
    margin-bottom: 30px;
    display: flex;
}

.service {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    
}

.service-wrapper .icon {
    margin-right: 20px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.service-wrapper .content {
    height: 100%;
    flex: 1;
}

.service-wrapper .content h4 {
    display: inline-block;
    vertical-align: middle;
    margin-top: 15px;
}

.service-wrapper .content .description {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.red-title {
    color: rgb(198, 25, 46);
}

.blue-title {
    color: rgb(55, 138, 165);
}

</style>