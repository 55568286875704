import { render, staticRenderFns } from "./cloud_platform.vue?vue&type=template&id=7fbcb6db&scoped=true"
import script from "./cloud_platform.vue?vue&type=script&lang=js"
export * from "./cloud_platform.vue?vue&type=script&lang=js"
import style0 from "./cloud_platform.vue?vue&type=style&index=0&id=7fbcb6db&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fbcb6db",
  null
  
)

export default component.exports