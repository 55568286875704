<template>
    <div ref="chart" style="width: 100%;height: 400px;"></div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    name: 'Bar',
    props: {
        xAxisData: {
            type: Array,
            required: true,
            default: () => []
        },
        yAxisData: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            chart: null,
            resizeObserver: null
        }
    },
    methods: {
        InitChart() {
            if (this.$refs.chart.offsetHeight === 0) {
                setTimeout(() => this.InitChart(), 100);
                return;
            }

            if (this.chart) {
                this.disposeChart();
            }
            
            this.chart = echarts.init(this.$refs.chart);

            const option = {
                xAxis: {
                    type: 'category',
                    data: this.xAxisData,
                    axisLabel: {
                        rotate: 45,
                        fontSize: 12,
                        color: '#fff',
                        formatter: function (value) {
                            return value.split(' ').join('\n');
                        },
                    },
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        data: this.yAxisData,
                        type: 'bar',
                        itemStyle: {
                            color: '#800000',
                            emphasis: {
                                color: 'rgba(255, 0, 0)',
                            },
                        },
                    },
                ],
                grid: {
                    left: '5%',
                    right: '5%',
                    top: '15%',
                    bottom: '25%',
                },
            };

            this.chart.setOption(option);
        },
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        },
        disposeChart() {
            if (this.chart) {
                this.chart.dispose();
                this.chart = null;
            }
        },
    },
    mounted() {
        this.resizeObserver = new ResizeObserver(() => {
            this.resizeChart();
        });
        this.resizeObserver.observe(this.$refs.chart);

        this.$nextTick(() => {
            const tabPanes = document.querySelectorAll('.tab-pane');
            tabPanes.forEach(pane => {
                pane.addEventListener('shown.bs.tab', () => {
                    this.resizeChart();
                });
            });
        });

        this.InitChart();
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        this.disposeChart();
        window.removeEventListener('resize', this.resizeChart);
    },
    watch: {
        xAxisData() {
            this.$nextTick(() => {
                this.InitChart();
            });
        },
        yAxisData() {
            this.$nextTick(() => {
                this.InitChart();
            });
        }
    }
};
</script>

<style scoped>
div {
    min-width: 300px;
}
</style>
